@use '../../designSystem/common/sassVars' as *;

.siteFooter__social {
    grid-area: social;

    span,
    svg {
        pointer-events: none;
    }

    @media (max-width: $scr-sm) {
        li {
            margin-bottom: 0 !important;
        }
    }

    @media (min-width: $scr-sm-min) {
        li {
            margin-bottom: 2px !important;
            margin-left: -5px;
        }

        svg {
            width: 25px;
            vertical-align: top;
        }

        span {
            display: inline-block;
            margin-left: 5px;
            vertical-align: -2px;
        }
    }

    @media (max-width: $scr-sm) {
        span {
            display: none;
        }

        ul {
            display: flex;
            justify-content: space-between;
            margin-left: -15px;
        }

        svg {
            width: 40px;
            height: 40px;
        }
    }
}
