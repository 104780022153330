@use '../designSystem/common/sassVars' as *;

.paging,
.loadMore {
	background-color: var(--grey-100);
	min-height: 70px;
	position: relative;
}

.paging {
	line-height: 30px;
	padding: 20px 20px 10px;

	@media (min-width: $scr-lg-min) {
		padding: 30px 30px 20px;
	}
}

.paging>* {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	color: #000;
	margin-right: 6px;
	margin-bottom: 10px;
	padding: 0 3px;
	min-width: 30px;
}

.paging>li>a {
	color: #000;
	display: block;
}

.paging>a,
.paging>li {
	background-color: #fff;
	font-weight: 600;
	cursor: pointer;
}

.paging>li.break-me {
	background-color: transparent;
}

.paging>li.disabled {
	pointer-events: none;

	a {
		opacity: .3;
	}
}

.paging span.on,
.paging .active {
	background-color: var(--primary-500);
	color: #fff;
}

.paging .active a {
	color: #fff;
}

.loadMore {
	line-height: 70px;

	a {
		display: block;
		text-align: center;
		font-size: 16px;
		color: #000;
		position: relative;

		&:hover {
			color: var(--primary-500);
			opacity: 1;
		}
	}

	.archive {
		position: absolute;
		top: 0;
		right: 20px;
		font-size: 14px;
		opacity: .3;

		@media (min-width: $scr-md-min) {
			right: 30px;
		}

		@media (max-width: $scr-xs) {
			display: none;
		}
	}

	#loading,
	.loading {
		border: 5px solid #fff;
		border-left: 5px solid black;
		border-top: 5px solid black;
		border-radius: 50%;
		height: 30px;
		width: 30px;
		left: 50%;
		top: 50%;
		margin: -15px 0 0 -15px;
		opacity: 0.3;
		position: absolute;
		animation: loading .7s infinite linear;
		overflow: hidden;
		text-indent: -999px;
	}
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
