@use '../designSystem/common/sassVars' as *;

@media (min-width: $scr-xxs-min) {
	.col-xxs-12 {
		flex-basis: 100%;
		max-width: 100%;
	}
}

// 480
@media (max-width: $scr-xs) {
	.hide-xs {
		display: none;
	}
}

@media (min-width: $scr-xs-min) {
	.col-xs-4 {
		flex-basis: 33.3333%;
		max-width: 33.3333%;
	}

	.col-xs-6 {
		flex-basis: 50%;
		max-width: 50%;
	}

	.col-xs-8 {
		flex-basis: 66.6666%;
		max-width: 66.6666%;
	}
}

// 768
@media (max-width: $scr-sm) {
	.hide-sm {
		display: none;
	}

	.text-center-sm-down {
		text-align: center;
	}
}

@media (min-width: $scr-sm-min) {
	.col-sm-2 {
		flex-basis: 16.6666%;
		max-width: 16.6666%;
	}

	.col-sm-3 {
		flex-basis: 25%;
		max-width: 25%;
	}

	.col-sm-4 {
		flex-basis: 33.3333%;
		max-width: 33.3333%;
	}

	.col-sm-5 {
		flex-basis: 41.6666%;
		max-width: 41.6666%;
	}

	.col-sm-6 {
		flex-basis: 50%;
		max-width: 50%;
	}

	.col-sm-7 {
		flex-basis: 58.3333%;
		max-width: 58.3333%;
	}

	.col-sm-9 {
		flex-basis: 75%;
		max-width: 75%;
	}

	.col-sm-10 {
		flex-basis: 83.3333%;
		max-width: 83.3333%;
	}
}

@media (min-width: $scr-md-min) {
	.col-md-6 {
		flex-basis: 50%;
		max-width: 50%;
	}

	.col-md-12 {
		flex-basis: 100%;
		max-width: 100%;
	}
}

@media (min-width: $scr-lg-min) {
	.col-lg-3 {
		flex-basis: 25%;
		max-width: 25%;
	}

	.col-lg-4 {
		flex-basis: 33.3333%;
		max-width: 33.3333%;
	}

	.col-lg-5 {
		flex-basis: 41.6666%;
		max-width: 41.6666%;
	}

	.col-lg-6 {
		flex-basis: 50%;
		max-width: 50%;
	}

	.col-lg-7 {
		flex-basis: 58.3333%;
		max-width: 58.3333%;
	}

	.col-lg-8 {
		flex-basis: 66.6666%;
		max-width: 66.6666%;
	}

	.col-lg-12 {
		flex-basis: 100%;
		max-width: 100%;
	}
}

@media (min-width: $scr-xlg-min) {
	.col-xlg-3 {
		flex-basis: 25%;
		max-width: 25%;
	}
}


.row {
	display: flex;
	flex-wrap: wrap;

	&.mb20 {
		margin-bottom: 20px;
	}

	&>* {
		box-sizing: border-box;
		flex-basis: 100%;
		background-clip: content-box;
	}

	&-equal>* {
		display: flex;

		&>* {
			width: 100%;
		}
	}


	&.gutter20 {
		margin-left: -10px;
		margin-right: -10px;

		&>* {
			padding: 0 10px 20px;
		}
	}

	&.gutter10 {
		margin-left: -5px;
		margin-right: -5px;

		&>* {
			padding: 0 5px 10px;
		}
	}

	&.gutter1 {
		margin-left: -1px;
		margin-right: -1px;

		&>* {
			padding-top: 0;
			padding-left: 1px;
			padding-right: 1px;
		}
	}

	&.gutter30 {
		margin-left: -10px;
		margin-right: -10px;

		@media (min-width: $scr-lg-min) {
			margin-left: -15px;
			margin-right: -15px;

			&>* {
				padding: 0 15px 30px;
			}
		}

		&>* {
			padding: 0 10px 20px;
		}
	}



	&-middle {
		align-items: center;
	}


}
