html {
	font-family: sans-serif; // 1
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block;
}

// Links
// ==========================================================================

// Remove the gray background color from active links in IE 10.
a {
	background-color: transparent;
}

// Improve readability of focused elements when they are also in an
// active/hover state.
a:active,
a:hover {
	outline: 0;
}

// Text-level semantics
// ==========================================================================

// Address styling not present in IE 8/9/10/11, Safari, and Chrome.
// Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
// Address styling not present in Safari and Chrome.
small {
	font-size: 80%;
}


// Embedded content
// ==========================================================================

// Remove border when inside `a` element in IE 8/9/10.
img {
	border: 0;
}

// Correct overflow not hidden in IE 9/10/11.
svg:not(:root) {
	overflow: hidden;
}

// Grouping content
// ==========================================================================

// Address margin not present in IE 8/9 and Safari.
figure {
	margin: 0;
}

// Address differences between Firefox and other browsers.
hr {
	box-sizing: content-box;
	height: 0;
}

// Contain overflow in all browsers.

// Forms
// ==========================================================================

// Known limitation: by default, Chrome and Safari on OS X allow very limited
// styling of `select`, unless a `border` property is set.

// 1. Correct color not being inherited.
//    Known issue: affects color of disabled elements.
// 2. Correct font properties not being inherited.
// 3. Address margins set differently in Firefox 4+, Safari, and Chrome.

button,
input,
select,
textarea {
	color: inherit; // 1
	font: inherit; // 2
	margin: 0; // 3
}

// Address `overflow` set to `hidden` in IE 8/9/10/11.
button {
	overflow: visible;
	padding: 0;
	outline: none;
	background-color: transparent;
	border: none;
}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
// Correct `select` style inheritance in Firefox.

button,
select {
	text-transform: none;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.

button,
html input[type="button"],
// 1
input[type="reset"],
input[type="submit"] {
	appearance: button; // 2
	cursor: pointer; // 3
}

// Re-set default cursor for disabled elements.

button[disabled],
html input[disabled] {
	cursor: default;
}

// Remove inner padding and border in Firefox 4+.

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

// Address Firefox 4+ setting `line-height` on `input` using `!important` in
// the UA stylesheet.

input {
	line-height: normal;
}

// It's recommended that you don't attempt to style these elements.
// Firefox's implementation doesn't respect box-sizing, padding, or width.
// 1. Address box sizing set to `content-box` in IE 8/9/10.
// 2. Remove excess padding in IE 8/9/10.

input[type="checkbox"],
input[type="radio"] {
	padding: 0; // 2
}

// 1. Address `appearance` set to `searchfield` in Safari and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari and Chrome.

input[type="search"] {
	appearance: textfield; // 1
}

// Remove inner padding and search cancel button in Safari and Chrome on OS X.
// Safari (but not Chrome) clips the cancel button when the search input has
// padding (and `textfield` appearance).

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	appearance: none;
}

// Define consistent border, margin, and padding.

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

// 1. Correct `color` not being inherited in IE 8/9/10/11.
// 2. Remove padding so people aren't caught out if they zero out fieldsets.

legend {
	border: 0; // 1
	padding: 0; // 2
}

// Remove default vertical scrollbar in IE 8/9/10/11.

textarea {
	overflow: auto;
}


// Tables
// ==========================================================================

// Remove most spacing between table cells.

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}
