.ds-button {
	&.ds-button--large {
		padding: var(--button-padding-large);
		font-size: var(--button-font-size-large);
		line-height: var(--button-line-height-large);
		min-height: var(--button-min-height-large);

		&.ds-button--iconOnly {
			padding: var(--button-icononly-padding-large);
		}

		&.ds-button--loading::before {
			width: 20px;
			height: 20px;
		}

		img, svg {
			max-height: 24px;
		}

	}

	&.ds-button--medium {
		padding: var(--button-padding-medium);
		font-size: var(--button-font-size-medium);
		line-height: var(--button-line-height-medium);
		min-height: var(--button-min-height-medium);

		&.ds-button--iconOnly {
			padding: var(--button-icononly-padding-medium);
		}

		&.ds-button--loading::before {
			width: 16px;
			height: 16px;
		}

		img, svg {
			max-height: 20px;
		}
	}

	&.ds-button--small {
		padding: var(--button-padding-small);
		font-size: var(--button-font-size-small);
		line-height: var(--button-line-height-small);
		min-height: var(--button-min-height-small);

		&.ds-button--iconOnly {
			padding: var(--button-icononly-padding-small);
		}

		&.ds-button--loading::before {
			width: 14px;
			height: 14px;
		}

		img, svg {
			max-height: 18px;
		}
	}
}
