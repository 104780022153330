@mixin rounded-card($hover: false, $color: var(--grey-400)) {
	border: 1px solid $color;
	border-radius: var(--rad);

	@if $hover {
		&:hover {
			border-color: #000;
		}
	}
}
