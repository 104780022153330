@use '../common/sassVars' as *;

%icon-tool-tip {
	@media (min-width: $scr-sm-min) {
		&:hover::before {
			background-color: #000;
			border-radius: 4px;
			color: #fff;
			content: attr(data-title);
			font-size: 10px;
			font-weight: 600;
			left: 50%;
			line-height: 20px;
			padding: 0 10px;
			position: absolute;
			text-transform: uppercase;
			top: -25px;
			transform: translateX(-50%);
			white-space: nowrap;
		}
	}
}
