.videoLength {
	background-color: rgba(0 0 0 / 80%);
	bottom: 5px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	padding: 0 8px;
	position: absolute;
	right: 5px;
	color: #fff;
	font-style: normal;
	border-radius: 2px;
}
