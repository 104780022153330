@use '../designSystem/common/sassVars' as *;

.loginForm {
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		text-align: center;
	}

	p+p {
		margin-top: -6px;
	}

	p.lighter {
		font-size: 13px;
		color: var(--grey-700);
	}

	p.light {
		color: var(--grey-700);
	}

	p+.pn-field.width-btn {
		margin-top: 3px;
	}

	.forgot {
		align-self: flex-end;
		position: relative;
		margin-top: 5px;
	}

	.forgot>a {
		font-size: 13px;
	}

	.pn-field {
		margin-bottom: 8px;
	}

	.tip {
		display: none;
	}

	.needAttention .tip {
		display: block;
	}

	.tip.last {
		margin-bottom: 5px;
		margin-top: 20px;
	}
}

.loginModalContainer {
	padding: 40px 60px;

	@media (max-width: $scr-xs) {
		padding: 10px 20px;
	}

	&>b {
		font-size: 22px;
		line-height: 42px;
		display: block;
		text-align: center;
		margin: 0 0 15px;

		@media (max-width: $scr-xs) {
			line-height: 32px;
			margin: 0 40px 15px;
		}
	}

	.footer {
		font-size: 13px;
		line-height: 26px;
		text-align: center;
		color: var(--grey-700);
		margin-top: 30px;

		@media (max-width: $scr-xxs) {
			a {
				display: block;
			}
		}
	}
}

.LoginModalWindow {
	position: fixed !important;

	@media (min-width: $scr-xs-min) {
		height: 90vh !important;
		max-height: 730px !important;
		top: 50% !important;
		transform: translateY(-50%);
	}

	@media (max-width: $scr-xs) {
		inset: 0 !important;
		margin: 0 !important;
		width: auto !important;
		height: auto !important;
	}

	@media (min-height: 680px) and (max-width: $scr-xs) {
		display: flex;
		align-items: center;
	}

	.modalWindowCloseButton {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.loginForm__social+.loginForm__social {
	margin-top: 10px;
}
