@use '../designSystem/common/sassVars' as *;
@use "header/logo";
@use "header/mobileToggle";
@use "header/features";
@use "header/search/base" as search;
@use "header/menu";
@use "header/userMenu";
@use "header/saferGambling";
@use "header/liveNowTop";
@use "../components/export/preloader";
@use "../components/conversionBanner";
@use "header/submenu/base" as submenu;

body {
	@media (max-width: $navbar-breakpoint-max) {
		padding-top: var(--navbar-height);
	}
}


.navbar {
	background-color: #fff;
	z-index: 10;
	display: grid;

	@extend %preloader;

	@media (min-width: $navbar-breakpoint-min) {
		grid-template: "navbar__logo navbar__search" var(--navbar-height) "navbar__live navbar__live" auto "navbar__menu navbar__menu" auto / 1fr auto;
		position: relative;
	}

	@media (max-width: $navbar-breakpoint-max) {
		grid-template-areas: "navbar__logo navbar__live navbar__search navbar__toggler";
		grid-template-columns: 1fr auto auto 65px;
		column-gap: 4px;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0 1px 0 var(--grey-400);
		height: var(--navbar-height);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}

	&__mobile {
		grid-area: navbar__menu;

		@media (max-width: $navbar-breakpoint-max) {
			background-color: #fff;
			position: fixed;
			bottom: 0;
			right: 0;
			top: calc(var(--navbar-height) + 1px);
			transform: translateX(100%);
			overflow-y: auto;
			overscroll-behavior: contain;
			padding: 20px 20px 80px;
			width: 100%;
			max-width: var(--mobile-menu-max-width);
		}

		@media (max-width: 500px) {
			max-width: none;
		}

	}
}

.activeMenu {
	.navbar__mobile {
		transform: none;
	}


	@media (max-width: $navbar-breakpoint-max) {
		body {
			overflow: hidden;
		}

		#container::before {
			content: '';
			position: absolute;
			inset: 0;
			background-color: rgb(0 0 0 / 30%);
			z-index: 4;
		}
	}

}

.activeDesktopMenu {
	#container {
		filter: brightness(0.7);
	}
}

.clickIgnore {
	pointer-events: none;
}

.moderate {
	display: none;
}

#geoForced {
	background-color: var(--primary-500);
	color: #fff;
	letter-spacing: -1px;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	border-radius: var(--rad);

	@media (min-width: $navbar-breakpoint-min) {
		padding: 5px 20px;
	}

	&::after {
		color: #fff;
		content: "X";
		font-size: 12px;
		line-height: 14px;
		opacity: .5;
		position: absolute;
		right: 4px;
		top: 2px;
	}
}

@media (min-width: $navbar-breakpoint-min) {
	.virgutis--top {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.hasWallpaper .virgutis--top {
		--aspect-ratio-w: 1380;
		--aspect-ratio-h: 130;

		max-width: $web-width;
		margin-top: 0;
		margin-bottom: 0;
	}

	.virgutis--topMobile {
		display: none;
	}

	.navMore {
		transition: top .4s;
	}

	.tocFixed {
		transition: margin-top .4s;
	}
}


@media (max-width: $navbar-breakpoint-max) {
	.tocFixed,
	.navMore.isSticky {
		top: var(--navbar-height) !important;
	}

	.virgutis--top {
		display: none !important;
	}

	.breadcrumb~.virgutis--topMobile {
		margin: -15px auto 15px !important;
	}

	.tourHub__header,
	#myStack,
	.review>figure,
	.podcastShow header {
		margin-top: 0 !important;
	}

	#reportingNotifications .stickedNote {
		top: 150px;
	}
}

#body-tours.isAdmBox,
#body-reporting.isAdmBox {
	.editButton {
		display: none !important;
	}
}

@media (min-width: $navbar-breakpoint-min) {
	body {
		.navbar {
			position: sticky;
			top: -145px;
			transition: top .4s ease;
		}

		&.navbarSticky {
			.navbar {
				top: 0;
			}
		}
	}
}
