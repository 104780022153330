.ds-subscribe.ds-subscribe--sidebar {
	background-color: #003F7D;
	background-image: url('../../../web/img/svg/subscribe-doodle.svg');
	background-size: cover;
	background-position: center;
	padding: 30px 10px 20px;

	.ds-subscribe__main__title, .ds-subscribe__main__text {
		text-align: center;
	}

	.ds-subscribe__main__title {
		margin-bottom: 20px;
	}

	.ds-subscribe__main__text {
		p {
			font-size: 14px;
			line-height: 20px;
		}

		small {
			font-size: 11px;
			line-height: 14px;
			display: block;
			margin-bottom: 20px;
			margin-top: -10px;
			opacity: .7;
		}
	}

	.ds-formInput {
		text-align: center;
	}

	.ds-subscribe__footer {
		&>small {
			display: none;
		}

		&>img {
			display: block;
			margin-top: 20px;
		}
	}

}
