.ds-ratingBar {
	display: inline-flex;
	justify-content: center;
	position: relative;
	width: 110px;
	margin: 0 auto;

	&__overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-color: var(--room-header-bg);
		opacity: .7;

		@supports (mix-blend-mode:color) {
			opacity: unset;
			mix-blend-mode: color;
		}
	}
}
