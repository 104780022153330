.ds-darkTheme {
	.ds-formTomSelect  {
		.ts-control {
			background-color: transparent !important;

			&::after {
				filter: invert(1) brightness(2);
			}

			input, .item {
				color: #fff;
			}

			input::placeholder {
				color: inherit;
			}
		}
	}
}
