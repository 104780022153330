@forward "./core/variables";
@forward "./core/content";
@forward "./core/types";
@forward "./core/sizes";
@forward "./core/social";
@forward "custom";

.ds-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--rad);
	cursor: pointer;
	transition: all 0.3s ease;
	font-weight: 600;
	white-space: nowrap;
	font-family: inherit;
	text-align: center;
	text-transform: uppercase;

	// Disable the button if needed
	&--disabled,
	&:disabled {
		cursor: not-allowed;
		opacity: var(--button-disabled-opacity);
	}

	// Loading state
	&--loading {
		position: relative;
		pointer-events: none;

		&::before {
			content: "";
			position: absolute;
			border-radius: 50%;
			animation: spin 1s linear infinite;
		}

		& > * {
			visibility: hidden;
		}
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
}
