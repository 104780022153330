:root {
	// Sizes
	--button-padding-large: 11px 28px;
	--button-padding-medium: 7px 24px;
	--button-padding-small: 3px 20px;
	--button-icononly-padding-large: 11px;
	--button-icononly-padding-medium: 9px;
	--button-icononly-padding-small: 6px;
	--button-min-height-large: 48px;
	--button-min-height-medium: 40px;
	--button-min-height-small: 32px;

	// Typography
	// With Uppercase
	--button-font-size-large: 16px;
	--button-font-size-medium: 14px;
	--button-font-size-small: 12px;

	// No Uppercase
	// --button-font-size-large: 18px;
	// --button-font-size-medium: 16px;
	// --button-font-size-small: 14px;
	--button-line-height-large: 24px;
	--button-line-height-medium: 24px;
	--button-line-height-small: 24px;

	// Colors
	// BG colors
	--button-primary-bg: var(--primary-500);
	--button-secondary-bg: transparent;
	--button-darktheme-bg: transparent;
	--button-ghost-bg: var(--grey-100);
	--button-tertiary-bg: transparent;
	--button-primary-bg-hover: var(--primary-600);
	--button-secondary-bg-hover: var(--primary-500);
	--button-darktheme-bg-hover: #fff;
	--button-ghost-bg-hover: #E7E7E7;
	--button-tertiary-bg-hover: transparent;

	// Text color
	--button-primary-color: #fff;
	--button-secondary-color: var(--primary-500);
	--button-darktheme-color: #fff;
	--button-ghost-color: #000;
	--button-tertiary-color: var(--primary-500);
	--button-primary-color-hover: #fff;
	--button-secondary-color-hover: #fff;
	--button-darktheme-color-hover: #000;
	--button-ghost-color-hover: #000;
	--button-tertiary-color-hover: var(--primary-500);

	// Border color
	--button-primary-border: var(--button-primary-bg);
	--button-secondary-border: var(--primary-500);
	--button-darktheme-border: #fff;
	--button-ghost-border: var(--button-ghost-bg);
	--button-tertiary-border: var(--button-tertiary-bg);
	--button-primary-border-active: #C2E1FF;
	--button-secondary-border-active: #C2E1FF;
	--button-darktheme-border-active: #fff;
	--button-ghost-border-active: #E6E6E6;
	--button-tertiary-border-active: var(--primary-500);

	// States
	--button-disabled-opacity: 0.4;
}

.ds-button--danger {
	--primary-500: #BC070E;
	--button-primary-bg: var(--primary-500);
	--button-primary-bg-hover: #EA2D35;
	--button-primary-border: var(--button-primary-bg);
	--button-primary-border-active: #F8E6E7;
	--button-secondary-bg-hover: var(--primary-500);
	--button-secondary-color: var(--primary-500);
	--button-secondary-border: var(--primary-500);
	--button-secondary-border-active: var(--button-primary-border-active);
	--button-ghost-bg-hover: #E7E7E7;
	--button-ghost-color: var(--primary-500);
	--button-ghost-color-hover: var(--primary-500);
	--button-tertiary-color: var(--primary-500);
	--button-tertiary-color-hover: var(--primary-500);
	--button-tertiary-border-active: var(--primary-500);
}
