@use '../mixins/rounded-card' as *;

.ds-socialDropdown {
	position: relative;
	cursor: pointer;

	&__icon {
		display: inline-block;
		background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRhdGEtbmFtZT0iTGF5ZXIgMiIgdmlld0JveD0iMCAwIDE1IDE2Ij48cGF0aCBkPSJNMTIuNSAxNmMtLjY2LjAxLTEuMy0uMjQtMS43Ny0uNy0uNDctLjQ0LS43NC0xLjA2LS43My0xLjcgMC0uMSAwLS4xOS4wMi0uMjkuMDEtLjA5LjAzLS4xOC4wNi0uMjdMNC4yIDkuNzZjLS4yNC4yLS41LjM2LS43OS40Ny0uMjkuMTEtLjYuMTctLjkyLjE3LS42Ni4wMS0xLjMtLjI0LTEuNzctLjdDLjI2IDkuMjYgMCA4LjY1IDAgOGMwLS42NC4yNi0xLjI2LjczLTEuNy40Ny0uNDYgMS4xMS0uNzEgMS43Ny0uNy4zMSAwIC42Mi4wNi45Mi4xNy4yOS4xMS41Ni4yNy43OS40N2w1Ljg4LTMuMjhjLS4wMy0uMDktLjA1LS4xOC0uMDYtLjI3LS4wMS0uMS0uMDItLjE5LS4wMi0uMjkgMC0uNjQuMjYtMS4yNi43My0xLjcuNDYtLjQ2IDEuMS0uNzEgMS43Ni0uNy42Ni0uMDEgMS4zLjI0IDEuNzcuNy40Ny40NC43NCAxLjA2LjczIDEuNyAwIC42NC0uMjYgMS4yNi0uNzMgMS43LS40Ny40Ni0xLjExLjcxLTEuNzcuNy0uMzEgMC0uNjItLjA2LS45Mi0uMTctLjI5LS4xMS0uNTYtLjI3LS43OS0uNDdMNC45MSA3LjQ0Yy4wMy4wOS4wNS4xOC4wNi4yNy4wMS4xLjAyLjE5LjAyLjI5IDAgLjEgMCAuMTktLjAyLjI5LS4wMS4wOS0uMDMuMTgtLjA2LjI3bDUuODggMy4yOGMuMjQtLjIuNS0uMzYuNzktLjQ3LjI5LS4xMS42LS4xNy45Mi0uMTcuNjYtLjAxIDEuMy4yNCAxLjc3LjcuNDcuNDQuNzQgMS4wNi43MyAxLjcgMCAuNjQtLjI2IDEuMjYtLjczIDEuNy0uNDcuNDYtMS4xMS43MS0xLjc3LjdabTAtMTIuOGMuMjIgMCAuNDMtLjA4LjU5LS4yMy4xNi0uMTUuMjQtLjM1LjI0LS41NyAwLS4yMi0uMDgtLjQyLS4yNC0uNTctLjE2LS4xNS0uMzctLjI0LS41OS0uMjMtLjIyIDAtLjQzLjA4LS41OS4yMy0uMTYuMTUtLjI0LjM1LS4yNC41NyAwIC4yMi4wOC40Mi4yNC41Ny4xNi4xNS4zNy4yNC41OS4yM1ptLTEwIDUuNmMuMjIgMCAuNDMtLjA4LjU5LS4yMy4xNi0uMTUuMjQtLjM1LjI0LS41NyAwLS4yMi0uMDgtLjQyLS4yNC0uNTctLjE2LS4xNS0uMzctLjI0LS41OS0uMjMtLjIyIDAtLjQzLjA4LS41OS4yMy0uMTYuMTUtLjI0LjM1LS4yNC41NyAwIC4yMi4wOC40Mi4yNC41Ny4xNi4xNS4zNy4yNC41OS4yM1ptMTAgNS42Yy4yMiAwIC40My0uMDguNTktLjIzLjE2LS4xNS4yNC0uMzUuMjQtLjU3IDAtLjIyLS4wOC0uNDItLjI0LS41Ny0uMTYtLjE1LS4zNy0uMjQtLjU5LS4yMy0uMjIgMC0uNDMuMDgtLjU5LjIzLS4xNi4xNS0uMjQuMzUtLjI0LjU3IDAgLjIyLjA4LjQyLjI0LjU3LjE2LjE1LjM3LjI0LjU5LjIzWiIgZGF0YS1uYW1lPSJMYXllciAxIiBzdHlsZT0iZmlsbDojNDY0NjQ2O3N0cm9rZS13aWR0aDowIi8+PC9zdmc+') no-repeat center / 15px;
		height: 32px;
		pointer-events: none;
		width: 32px;
		border-radius: var(--rad);
		border: 1px solid var(--grey-400);
		z-index: 1;
	}

	&__content {
		@include rounded-card;

		display: none;
		position: absolute;
		top: calc(100% + 8px);
		right: -1px;
		background-color: #fff;
		z-index: 2;
		padding: 12px 0;


		a {
			color: #000;
		}

		& > * {
			padding: 12px 18px;
			display: grid;
			grid-template-columns: auto 1fr;
			column-gap: 12px;
			align-items: center;
			white-space: nowrap;
			font-size: 14px;

			&::before {
				content: '';
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				display: block;
				width: 18px;
				height: 18px;
				filter: brightness(0);
				opacity: 0.7;
			}

			&:hover {
				background-color: var(--grey-100);
			}
		}

		.social__copyLink {
			width: 100%;
			text-align: left;
			cursor: pointer;

			&::before {
				background-image: url('../../../web/img/svg/copyLink.svg');
			}

			&.copied {
				color: green;
			}
		}

		.social__twitter {
			&::before {
				background-image: url('../../../web/img/logos/twitter_dark.svg');
				background-size: 13px;
			}
		}

		.social__facebook {
			&::before {
				background-image: url('../../../web/img/svg/facebook.svg');
			}
		}
	}

	&.on {
		.ds-socialDropdown__content {
			display: block;
		}

	}

	&.on, &:hover {
		.ds-socialDropdown__icon {
			border-color: #000;
		}
	}
}

.noClipboardPermission .social__copyLink {
	display: none;
}
