@use '../../mixins/bp' as *;

.ds-subscribe--articleBase {
	@include bp-not-mobile {
		.ds-subscribe__header {
			max-width: 50%;
			margin-left: auto;
			margin-right: auto;
		}

		.ds-subscribe__main {
			max-width: 50%;
			margin: 0 auto;

			&__text {
				text-wrap: auto;
			}
		}
	}
}
