@use "../common/sassVars" as *;

@media (min-width: $scr-sm-min) {
    .hideInDesktop {
        display: none;
    }
}

@media (max-width: $scr-sm) {
    .hideInMobile {
        display: none;
    }
}
