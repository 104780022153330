.mobileScroll {
  display: block;
  max-height: 80%;
  height: 80vh;
  width: 100%;
  border: 0;
  overflow: hidden;
  position: relative;

  .space {
    position: absolute;
    height: 100%;
    height: 100vh;
    width: 100%;
    inset: 0 auto auto 0;
    overflow: hidden;
    clip: rect(0 auto auto 0);
    clip-path: inset(0);

    & > * {
      display: block;
      position: fixed;
      inset: 0 20px auto;
      height: 100%;
      background-color: #fff;
      background-size: contain;
      background-position: top 80px center;
      background-repeat: no-repeat;
      transform: translateZ(0);
    }
  }
}
