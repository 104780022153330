.ds-button {
    &.ds-button--social {
        border: 1px solid var(--grey-400);
        color: #1D1C2B;
        text-transform: none;

        &:hover {
            border-color: #000;
        }
    }
}
