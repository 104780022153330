.submenu__tinyCards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 10px;

	&--tours {
		display: inline-grid;

		a {
			grid-template-columns: 65px auto;
		}

		img {
			max-height: 30px;
		}
	}

	a {
		background-color: #fff;
		color: #000;
		display: grid;
		border-radius: 4px;
		grid-template-columns: 30px auto;
		align-items: center;
		column-gap: 10px;
		padding: 10px;
		line-height: 16px;
		overflow: hidden;
		border: 1px solid var(--grey-500);

		&:hover {
			border-color: #000;
			text-decoration: none !important;
		}

		img {
			vertical-align: top;
			justify-self: center;
		}
	}

	&--tools a {
		grid-template-columns: 27px auto;
	}

	&--states a {
		grid-template-columns: 45px auto;
		column-gap: 0;
		padding-top: 8px;

		img {
			transform: translateX(-3px);
		}
	}


}
