.siteFooter__copy {
    margin-bottom: 0;
    line-height: 20px;
    grid-area: copy;

    p {
        margin: 0 0 5px;
    }

    address {
        font-style: normal;
        color: var(--grey-600);
    }

    ul {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        margin-bottom: 20px;
    }
}
