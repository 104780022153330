.submenu__rules {
	display: inline-grid;
	column-gap: 10px;
	grid-template-columns: repeat(3, 1fr);

	&--howToPlay {
		a {
			height: 170px;
			color: #fff !important;
			text-align: center;
			text-transform: uppercase;
			display: block;
			padding: 100px 20px 25px;
			font-weight: bold;
			background-size: cover;
			background-position: top center;
		}
	}
}
