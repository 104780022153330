%video-play-button {
	width: var(--play-button-size);
	height: var(--play-button-size);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MS41IiBoZWlnaHQ9IjcxLjUiPjxwYXRoIGQ9Ik05LjU3MyAzNi45MzdxLS43ODkuNzg5LTEuODgyLS40MjVWNi44ODZxMS4xNTQtMS4yMTQgMS44ODItLjQyNUwzNC40IDIwLjU0NWExLjYxNCAxLjYxNCAwIDAgMSAwIDIuMzA3eiIgc3R5bGU9ImZpbGw6I2ZmZiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYuOTM4IDE0LjA1MSkiLz48cGF0aCBkPSJNMzUuNzUgMi45NzlhMzIuNzcxIDMyLjc3MSAwIDAgMC0yMy4xNzIgNTUuOTQzIDMyLjc3MSAzMi43NzEgMCAwIDAgNDYuMzQ0LTQ2LjM0NCAzMi41NTYgMzIuNTU2IDAgMCAwLTIzLjE3Mi05LjZtMC0yLjk3OEEzNS43NSAzNS43NSAwIDEgMSAwIDM1Ljc1IDM1Ljc1IDM1Ljc1IDAgMCAxIDM1Ljc1IDB6IiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-color: rgb(0 0 0 / 30%);
	border-radius: 50%;
}
