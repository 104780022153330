.preloaderBox--multipleRows {
	display: grid;
	margin-bottom: var(--preloader-box-mb, 30px);
	row-gap: var(--preloader-row-gap, 30px);

	i {
		display: block;
		pointer-events: none;
		border-radius: var(--rad);
		background-color: var(--grey-200);
		position: relative;
		overflow: hidden;
		height: var(--preloader-row-height-desktop, var(--preloader-row-height));

		&::before {
			content: '';
			position: absolute;
			inset: 0;
			animation: shimmer 1.5s infinite linear;
			background: linear-gradient(to right, var(--grey-200) 0%, #fff 50%, var(--grey-200) 100%);
		}
	}

	&.hidePreloader[data-hide="animate"] {
		height: 0;
		margin-bottom: 0;
		transition: all 1s;
		overflow: hidden;
	}

	&.hidePreloader[data-hide="instant"] {
		display: none;
	}
}
