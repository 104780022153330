// Define the base64 images for dark and light themes
$dark-theme-icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMTEgMjAuMTY2N2M1LjA2MjcgMCA5LjE2NjctNC4xMDQxIDkuMTY2Ny05LjE2NjcgMC01LjA2MjYtNC4xMDQtOS4xNjY3LTkuMTY2Ny05LjE2NjctNS4wNjI2IDAtOS4xNjY2IDQuMTA0LTkuMTY2NiA5LjE2NjcgMCA1LjA2MjYgNC4xMDQgOS4xNjY3IDkuMTY2NiA5LjE2NjdaIi8+PHBhdGggc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJtMTMuNzUgOC4yNS01LjUgNS41TTguMjUgOC4yNWw1LjUgNS41Ii8+PC9zdmc+';
$light-theme-icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PGVsbGlwc2UgY3g9IjguMDkiIGN5PSI4LjMzIiBmaWxsPSIjZmZmIiBjbGFzcz0iY2xzLTIiIHJ4PSI0LjkxIiByeT0iNS4wNSIvPjxwYXRoIGQ9Ik04IDBhOCA4IDAgMSAwIDAgMTZBOCA4IDAgMCAwIDggMFptMi45IDEwYy4yNS4yNS4yNS42NSAwIC45cy0uNjUuMjUtLjkgMGwtMi0yLTIgMmMtLjI1LjI4LS42Ny4zLS45NS4wNXMtLjMtLjY3LS4wNS0uOTVsMi0yLTItMmMtLjI4LS4yOC0uMjgtLjcyIDAtMXMuNzItLjI4IDEgMGwyIDIgMi0yYS42My42MyAwIDAgMSAuOSAwYy4yNS4yNS4yNS42NSAwIC45bC0yIDIgMiAyLjFaIiBjbGFzcz0iY2xzLTEiLz48L3N2Zz4='; // Replace with the actual light theme base64 data

// Define the mixin
@mixin close-button($size, $theme) {
  display: inline-block;
  border: none;
  outline: none;
  padding: 0;
  width: $size;
  height: $size;
  background: transparent no-repeat center;

  @if $theme == 'dark' {
    background-image: url($dark-theme-icon);
  } @else if $theme == 'light' {
    background-image: url($light-theme-icon);
  }

  cursor: pointer;
}
