.tweet {
	position: relative;
	overflow: hidden;
	display: grid;
	grid-template-areas:
		"photo author"
		"photo text";
	grid-template-columns: 48px auto;
	gap: 3px 15px;
	align-items: start;

	&:not(:last-child) {
		border-bottom: 1px solid var(--grey-400);
		padding-bottom: 10px;
		margin-bottom: 10px;
	}


	&__media {
		grid-area: photo;

		img {
			vertical-align: top;
			border-radius: 50%;
			box-shadow: 0 0 2px 0 rgb(0 0 0 / 40%);
		}
	}

	&__author {
		grid-area: author;
		margin-top: -5px;

		a:hover {
			text-decoration: underline;
		}

		.pnAlias {
			font-size: 14px;
			font-weight: 600;
		}

		.alias {
			font-size: 14px;
			display: none;
		}

		time {
			font-size: 13px;

			&::before {
				content: ' - ';
			}
		}
	}



	&__text {
		grid-area: text;
		display: block;
		font-size: 12px;
		line-height: 18px;
	}

	&__actions {
		position: absolute;
		right: 0;
		bottom: 0;
		opacity: 0;
		background-color: #f2f2f2;
		padding: 5px 10px;
		transition: all .2s;

		a {
			float: left;
			background-image: url('../../../web/img/icons/spriteTweeterBar.png?1');
			width: 16px;
			height: 16px;
			overflow: hidden;
			text-indent: -999px;
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}

		.tweetDelete {
			display: none;
		}

		.tweetReply:hover {
			background-position: -16px 0;
		}

		.tweetFavorite {
			background-position: -32px 0;

			&:hover {
				background-position: -48px 0;
			}

			&:active {
				background-position: -64px 0;
			}
		}

		.tweetRetweet {
			background-position: -80px 0;

			&:hover {
				background-position: -96px 0;
			}

			&:active {
				background-position: -112px 0;
			}
		}
	}

	&:hover .tweet__actions {
		opacity: 1;
	}

	&.tweet__sponsor {
		border: 0 none;
		background-color: #2193c3;
		margin: -11px -10px -10px;
		padding: 10px;

		* {
			color: #fff;
		}

		a:hover {
			color: #fff;
			text-decoration: underline;
		}

		&:hover .tweetActions {
			background-color: rgb(255 255 255 / 50%);
		}
	}
}
