@use '../../designSystem/common/sassVars' as *;

.navbar__logo {
	grid-area: navbar__logo;

	h1 {
		margin: 0;
		line-height: 0;
	}

	a {
		height: var(--navbar-height);
		overflow: hidden;
		text-indent: -999px;
		background-image: url("../../../web/img/logos/pokernews.svg");
		background-repeat: no-repeat;

		@media (min-width: $scr-lg-min) {
			background-position: var(--grid-side-padding-desktop) center;
		}

		@media (max-width: $scr-lg) {
			background-position: 18px center;
		}

		@media (min-width: $navbar-breakpoint-min) {
			display: inline-block;
			width: 240px;
			background-size: 200px 30px;
		}

		@media (max-width: $navbar-breakpoint-max) {
			display: block;
			background-size: 160px 23px;
		}
	}
}
