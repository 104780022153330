@use "../common/sassVars" as *;
@use '../mixins/rounded-card' as *;
@use "../exports/iconTooltip";
@use "../exports/listReset";
@use "../exports/baseTextFormat";


.ds-roomWidgetLayoutB {
	@include rounded-card;

	display: grid;
	margin-bottom: 20px;
	max-width: 700px;

	@media (min-width: $scr-sm-min) {
		margin-bottom: 40px;
	}


	@media (max-width: $scr-sm) {
		grid-template-areas: "graphic" "content";
		grid-template-rows: 220px 1fr;
	}

	@media (min-width: $scr-sm-min) {
		grid-template-areas: "graphic content";
		grid-template-columns: 230px 1fr;
	}

	header {
		grid-area: graphic;
		border-top-left-radius: var(--rad);
		border-bottom-left-radius: var(--rad);

		a::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-color: var(--grey-100);
		}

		.lazy-background.visible & {
			@media (min-width: $scr-sm-min) {
				a::before {
					background-image: var(--bg-desktop);
				}
			}

			@media (max-width: $scr-sm) {
				a::before {
					background-image: var(--bg-mobile, var(--bg-desktop));
				}
			}

			@media (min-width: $scr-sm-min) and (min-resolution: 2dppx) {
				a::before {
					background-image: var(--bg-desktop2x, var(--bg-desktop));
				}
			}

			@media (max-width: $scr-sm) and (min-resolution: 2dppx) {
				a::before {
					background-image: var(--bg-mobile2x, var(--bg-mobile, var(--bg-desktop)));
				}
			}
		}

	}

	&>div {
		grid-area: content;
		padding: 24px;
		position: relative;

	}

	&__badge {
		@extend %icon-tool-tip;

		position: absolute;
		right: 10px;
		top: 10px;
	}

	&__title {
		margin: 0 0 4px;
		font-weight: 700;

		@media (min-width: $scr-sm-min) {
			margin-bottom: 10px;
		}

		a {
			color: currentcolor;
		}

	}

	&__rating {
		@extend %base-text-format;

		display: grid;
		grid-template-columns: auto auto 1fr;
		column-gap: 4px;
		align-items: center;
		margin-bottom: 24px;

		span {
			color: var(--grey-700);
		}

		.ds-ratingBar {
			margin: 0;
		}
	}

	div.notAvailable {
		@extend %base-text-format;

		background-color: #e2ebf8;
		display: block;
		margin: 0 0 24px;
		padding: 10px;

		@media (min-width: $scr-sm-min) {
			padding: 7px 15px;
			margin-top: 14px;
			margin-bottom: 25px;
		}

		b {
			color: var(--grey-700);
			display: block;
			margin-bottom: 3px;
		}

	}


	&__offer {
		font-size: 18px;
		line-height: 28px;
		font-weight: 600;
		margin: 0 0 24px;

		@media (min-width: $scr-sm-min) {
			font-size: 22px;
			line-height: 28px;
		}

		button {
			vertical-align: 4px;
			margin-left: 6px;
		}
	}

	&__featuresTitle {
		@extend %base-text-format;

		margin: 0 0 4px;
		text-transform: uppercase;
		font-weight: 600;
	}

	&__featuresList {
		@extend %list-reset;
		@extend %base-text-format;

		margin-bottom: 20px;

		li {
			display: grid;
			grid-template-columns: 18px auto;
			column-gap: 4px;
			padding: 0;
			margin-bottom: 2px;

			&::before {
				content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIj48cGF0aCBkPSJNMzc4LTI0MXEtOSAwLTE3LjUtMy41VDM0NS0yNTVMMTY0LTQzNnEtMTQtMTQtMTQtMzR0MTQtMzRxMTQtMTQgMzMuNS0xNHQzNC41IDE0bDE0NiAxNDYgMzUwLTM0OXExNC0xNCAzMy41LTE0LjVUNzk1LTcwN3ExNCAxNCAxNCAzNHQtMTQgMzRMNDExLTI1NXEtNyA3LTE1LjUgMTAuNVQzNzgtMjQxWiIgZmlsbD0iIzAwODBmZiIvPjwvc3ZnPg==');
				position: relative;
				top: 4px;
			}

		}
	}

	&__reviewButton {
		margin-bottom: 24px;
		grid-area: review;

		@media (min-width: $scr-sm-min) {
			justify-self: start;
		}
	}

	&__extButton {
		margin-bottom: 24px;
		grid-area: cta;
	}

	.ds-termText {
		margin-bottom: 18px;
		grid-area: terms;
		color: var(--grey-700);
	}

	&__payments {
		grid-area: payments;
		display: flex;
		align-items: center;
		column-gap: 12px;

		@media (max-width: $scr-sm) {
			justify-content: center;
		}
	}

	footer {
		&.notAvailable {
			@media (min-width: $scr-sm-min) {
				grid-template-areas:
					"review empty"
					"payments payments";

				&.hasTerms {
					grid-template-areas:
						"review empty"
						"terms terms"
						"payments payments";
				}
			}
		}

		@media (min-width: $scr-sm-min) {
			display: grid;
			column-gap: 24px;
			grid:
				"cta review"
				"payments payments" / 1fr 1fr;

			&.hasTerms {
				grid:
					"cta review"
					"terms terms"
					"payments payments" / 1fr 1fr;
			}
		}
	}
}
