html {
	font-family: Arial, sans-serif;

	&.wf-active {
		font-family: 'Open Sans', Arial, sans-serif;
	}

}

body {
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
}

b,
strong {
	font-weight: 600;
}

time {
	color: var(--grey-700);
}

.tc_apply {
	display: inline-block;
	text-transform: uppercase;
	font-size: 8px;
	font-weight: 600;
	line-height: 14px;
	padding: 0 3px;
	border-radius: 16px;

	&:not(.tc_apply-dark) {
		background-color: #fff;
		border: 1px solid var(--primary-500);
		color: var(--primary-500);

		&:hover {
			background-color: var(--primary-500);
			color: #fff;
		}
	}


	&.tc_apply-dark {
		background-color: transparent;
		border: 1px solid #fff;
		color: #fff;

		&:hover {
			background-color: #fff;
			color: #000;
		}
	}
}

.terms {
	font-size: 9px;
	line-height: 10px;
	display: block;
	grid-area: terms;
	color: var(--term-color, var(--grey-700));
}

.widgetDisclaimer {
	font-style: italic;
	font-size: 13px !important;
	line-height: 22px;
}
