@use "../../common/sassVars" as *;

.preloaderBox--single {
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	height: var(--preloader-box-height);
	width: var(--preloader-box-width);
	border-radius: var(--preloader-box-radius, var(--rad));
	background-color: var(--grey-200);
	margin-bottom: var(--preloader-box-mb, 30px);
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		animation: shimmer 1.5s infinite linear;
		background: linear-gradient(to right, var(--grey-200) 0%, #fff 50%, var(--grey-200) 100%);
	}

	@media (min-width: $scr-sm-min) {
		height: var(--preloader-box-height-desktop, var(--preloader-box-height));
	}

	@media (max-width: $scr-sm) {
		width: var(--preloader-box-width-mobile, var(--preloader-box-width));
	}

	&.noData{
		animation: none;

		&::after {
			content: attr(data-nodata);
		}
	}

	&::after {
		content: attr(data-text);
		z-index: 1;
	}

	&.hidePreloader[data-hide="animate"] {
		height: 0;
		margin-bottom: 0;
		transition: all 1s;
		overflow: hidden;

		&::after {
			transition: opacity 1s;
			content: attr(data-text-empty);
			opacity: 0;
		}
	}
}
