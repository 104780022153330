.ds-darkTheme {
	.ds-formInput {
		background-color: transparent;
		color: #fff !important;

		&::placeholder {
			color: #fff !important;
		}
	}
}
