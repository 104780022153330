.k {
	display: inline-flex;
	justify-content: center;
	column-gap: 2px;
	border: 1px solid var(--grey-500);
	background: #fff;
	border-radius: var(--rad);
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	font-style: normal;
	padding: 0 2px;
	margin: 0 2px;
	min-width: 28px;
	flex-wrap: nowrap;
	text-transform: uppercase;
	position: relative;

	i {
		font-style: normal;
		font-size: 14px;
		position: relative;
		top: -1px;

	}

	&--big {
		width: 34px;
		height: 46px;
		flex-direction: column-reverse;
		align-items: end;
		font-size: 16px;
		padding: 2px 4px 5px 3px;
		font-weight: 600 !important;
		row-gap: 10px;
		margin-right: 2px;
		margin-bottom: 2px;

		i {
			align-self: flex-start;
			width: 11px !important;
			top: 0;
		}

		&[class*="h"],
		&[class*="d"] {
			color: red;
		}

		&[class*="x"] {
			color: var(--grey-700);

			i {
				font-size: 14px;
			}
		}
	}

	&[class*="h"] i,
	&[class*="c"] i,
	&[class*="d"] i,
	&[class*="s"] i {
		position: static;
		overflow: hidden;
		width: 9px;
		height: 16px;
		background-repeat: no-repeat;
		background-size: contain;
		text-indent: -999px;
		background-position: center;
	}

	&[class*="h"] i {
		background-image: url('../../../web/img/svg/suit-heart.svg');
	}

	&[class*="c"] i {
		background-image: url('../../../web/img/svg/suit-club.svg');
	}

	&[class*="d"] i {
		background-image: url('../../../web/img/svg/suit-diamond.svg');
	}

	&[class*="s"] i {
		background-image: url('../../../web/img/svg/suit-spade.svg');
	}

	&[class*="10"] {
		letter-spacing: -0.8px;
	}

	&[class*="h"] i,
	&[class*="d"] i {
		color: red;
	}

	&[class$="x"] i {
		color: var(--grey-700);
		font-size: 10px;
		top: 1px;
	}
}

.cards--alignright {
	.k--big {
		margin-right: 0;
		margin-left: 2px;
		text-align: left;
	}
}
