@use "../designSystem/mixins/triangle" as *;

img,
object,
iframe,
embed,
video {
	max-width: 100%;
}

* {
	box-sizing: border-box
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.hidden,
.pnPollHidden,
.hide {
	display: none
}

.clrfix::before,
.clrfix::after {
	content: "\0020";
	display: block;
	height: 0;
	visibility: hidden;
}

.clrfix::after {
	clear: both;
}

.clr {
	display: block;
	overflow: hidden;
	clear: both;
	height: 0;
	margin: -1px 0 0;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.mb40 {
	margin-bottom: 40px !important
}

.noWrap {
	white-space: nowrap;
}

th {
	font-weight: normal;
	text-align: left;
}

.relative {
	position: relative;
}

.embed-responsive,
.video-object {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}

	&-16by9 {
		padding-bottom: 56.25%;
	}
}

.video-object {
	padding-bottom: 56.25%;
}

.pswp__top-bar,
.pswp__caption {
	background-color: transparent !important;
}

.pswp img {
	object-fit: contain;
}

.playerPhoto:hover img {
	opacity: .8
}

.modalWindowCloseButton {
	width: 60px;
	height: 60px;
	background: url('../../../web/img/svg/close.svg') no-repeat center/25px 25px;
	opacity: .7;
	cursor: pointer;
}

.cloud {
	display: inline-block;
	line-height: 100%;
	margin: 0 20px 20px 0;
}

.playButton {
	background-color: #000;
	border-radius: 50%;
	bottom: 15px;
	border: 2px solid #fff;
	font-size: 13px;
	height: 30px;
	line-height: 19px;
	padding: 0;
	position: absolute;
	right: 15px;
	text-align: center;
	white-space: nowrap;
	width: 30px;
	color: #fff;

	&::after {
		@include triangle(right, 6px);

		content: '';
		display: inline-block;
		margin: 7px 0 0 2px;
	}
}

.loading button {
	position: relative;
	pointer-events: none;

	span {
		opacity: 0;
	}

	&::before {
		content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgb3BhY2l0eT0iLjIiIGQ9Ik0yMC4yIDUuMTdhMTQuOTUgMTQuOTUgMCAxIDAgMCAyOS45IDE0Ljk1IDE0Ljk1IDAgMCAwIDAtMjkuOXptMCAyNi41OGExMS42MyAxMS42MyAwIDEgMSAwLTIzLjI3IDExLjYzIDExLjYzIDAgMCAxIDAgMjMuMjd6Ii8+PHBhdGggZD0iTTI2LjAxIDEwLjA1bDEuNjYtMi44N2ExNC44NSAxNC44NSAwIDAgMC03LjQ3LTIuMDF2My4zMWMyLjEyIDAgNC4xLjU4IDUuODEgMS41N3oiIGZpbGw9IiNmZmZmZmYiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCIgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgMjAgMjAiIHRvPSIzNjAgMjAgMjAiIGR1cj0iMC41cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48L3BhdGg+PC9zdmc+');
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 40px;
	}
}
