@use "../../common/sassVars" as *;

.preloaderBox--multipleCols {
	display: grid;
	gap: var(--preloader-row-gap, 30px) var(--preloader-col-gap, 30px);
	grid-template-columns: repeat(var(--preloader-mobile-box-count), var(--preloader-box-width));
	margin-bottom: var(--preloader-box-mb, 30px);
	overflow: visible;

	@media (min-width: $scr-sm-min) {
		grid-template-columns: repeat(var(--preloader-desktop-box-count), var(--preloader-box-width));
	}

	i {
		pointer-events: none;
		border-radius: var(--rad);
		background-color: var(--grey-200);
		position: relative;
		overflow: hidden;
		height: var(--preloader-box-height); // Add explicit height to each item

		&::before {
			content: '';
			position: absolute;
			inset: 0;
			animation: shimmer 1.5s infinite linear;
			background: linear-gradient(to right, var(--grey-200) 0%, #fff 50%, var(--grey-200) 100%);
		}

		@media (min-width: $scr-sm-min) {
			height: var(--preloader-box-height-desktop, var(--preloader-box-height));
		}
	}

	&.hidePreloader[data-hide="animate"] {
		height: 0;
		margin-bottom: 0;
		transition: all 1s;
		overflow: hidden;

		&::after {
			transition: opacity 1s;
			content: attr(data-text-empty);
			opacity: 0;
		}
	}

	&.hidePreloader[data-hide="instant"] {
		display: none;
	}
}
