@use '../../mixins/border-arrow' as *;
@use "./tom-select";
@use "dark";

.ds-formTomSelect {
	width: 100%;
	min-height: 38px;
	padding: 0 16px;
	border: solid 1px var(--grey-400);
	border-radius: var(--rad);
	margin-bottom: 0;
	display: flex;
	align-items: center;

	&.ds-formTomSelect--medium {
		min-height: 40px;
	}

	.ts-dropdown {
		border: solid 1px var(--grey-400);
		border-radius: var(--rad);

		&-content {
			padding: 8px 0;
		}
	}

	.ts-control {
		position: relative;
		font-size: 16px;
		padding: 0;
		border: none;
		color: #000;

		input {
			color: #000;
			font-size: 16px;
			height: 18px;
		}

		.item {
			margin-right: 8px;
		}

		&::after {
			@include border-arrow(bottom, 14px, 8px);

			position: absolute;
			top: 6px;
			right: 0;
		}
	}

	&.disabled {
		.ts-control {
			opacity: 1;
			background-color: #fff;
		}
	}

	.optgroup:not(:last-child) {
		margin-bottom: 12px;
	}

	.optgroup-header {
		color: #000;
		padding: 8px 16px;
		font-size: 16px;
		font-weight: 600;
	}

	.option {
		color: #000;
		padding: 8px 16px;
		font-size: 16px;
		line-height: 22px;

		&.selected, &:hover, &.active {
			background-color: var(--grey-100);
		}
	}
}

.dropdown-active {
	.ts-control {
		&::after {
			@include border-arrow(top, 14px, 8px);
		}
	}
}
