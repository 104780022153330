@use "multipleCols";
@use "multipleRows";
@use "single";

.preloaderBox {
	&.hidePreloader[data-hide="instant"] {
		display: none;
	}
}

@keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
