@use "../designSystem/common/sassVars" as *;

@media (min-width: $scr-md-min) {
	.editorChoice {
		ul {
			list-style-type: disc;
			margin: 0 0 0 20px;
			padding: 20px;
		}

		li {
			&:not(:last-child) {
				margin-bottom: 20px;
			}

			a {
				font-weight: 600;
			}

		}

	}
}
