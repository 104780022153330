@use '../../designSystem/common/sassVars' as *;

.navbar__features {
	@media (max-width: 500px) {
		.navbar__feature {
			min-width: 17vw;
		}
	}

	@media (max-width: $navbar-breakpoint-max) {
		margin: 0 -20px;
		overflow-x: scroll;
		padding-bottom: 15px;
		scroll-snap-type: x mandatory;
		position: relative;

		&>.navbar__features__scroll {
			display: inline-flex;
			padding: 0 20px 0 0;
		}

		.navbar__feature {
			margin-left: 20px;
			scroll-snap-align: center;
			white-space: nowrap;
			padding: 0 5px;
		}

		.navbar--userMenuOn & {
			display: none;
		}
	}

	@media (min-width: $navbar-breakpoint-min) {
		&>.navbar__features__scroll {
			display: flex;
			flex-direction: row;
			position: absolute;
			top: 0;
			right: var(--grid-side-padding-desktop);
			height: var(--navbar-height);
			column-gap: var(--navbar-desktop-features-gap);
			align-items: center;
		}
	}


}

.navbar__feature {
	cursor: pointer;

	@media (min-width: $navbar-breakpoint-min) {
		&[data-nav="navbarModerate"] {
			order: 5
		}

		&[data-nav="navbarGeo"] {
			order: 6
		}

		&[data-nav="avatar"],
		&[data-nav="avatarOff"] {
			order: 7
		}

		&[data-nav="navbarWsop"] {
			img {
				width: 36px;
			}
		}

		&[data-nav="navbarPlayNow"] {
			img {
				width: 25px;
			}
		}

		&[data-nav="navbarSports"] {
			img {
				width: 27px;
			}
		}
	}

	.navbar__ico {
		vertical-align: middle;
		max-width: none;

		@media (min-width: $navbar-breakpoint-min) {
			width: 22px;
			height: 22px;
		}

		@media (max-width: $navbar-breakpoint-max) {
			display: block;
			margin: 0 auto 10px;
		}
	}

	&[data-nav="navbarModerate"] {
		color: #fff;

		@media (max-width: $navbar-breakpoint-max) {
			background: var(--orange-500);
		}

		@media (min-width: $navbar-breakpoint-min) {
			align-self: stretch;
			align-items: center;
			box-shadow: 10px 0 0 0 var(--orange-500), -10px 0 0 0 var(--orange-500);
			background-color: var(--orange-500);

			&:hover {
				background-color: var(--orange-600);
				box-shadow: 10px 0 0 0 var(--orange-600), -10px 0 0 0 var(--orange-600);
				color: #fff;
			}
		}

		.navbar__ico {
			filter: invert(1);

			@media (max-width: $navbar-breakpoint-max) {
				position: relative;
				top: 5px
			}
		}
	}

	&[data-nav="avatar"] {
		.avatarWrapper {
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		.navbar__ico {
			border-radius: 50%;
			vertical-align: top;
		}
	}

	@media (min-width: $navbar-breakpoint-min) {
		display: flex;
		column-gap: 6px;
	}

	@media (max-width: $navbar-breakpoint-max) {
		text-align: center;
	}

	&>span {
		display: block;
	}
}
