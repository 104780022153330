@use "./mixins/convert-color" as *;

.ds-button {
	&.ds-button--primary {
		background-color: var(--button-primary-bg);
		color: var(--button-primary-color);
		border: 1px solid var(--button-primary-border);

		&:hover {
			background-color: var(--button-primary-bg-hover);
			color: var(--button-primary-color-hover);
			border-color: var(--button-primary-bg-hover);
		}

		&:active {
			background-color: var(--button-primary-bg-hover);
			color: var(--button-primary-color-hover);
			border-color: var(--button-primary-border-active);
		}

		&.ds-button--loading::before {
			border: 2px solid rgba(255 255 255 / 15%);
			border-top-color: #fff;
		}

		img, svg {
			@include to-white;
		}
	}

	&.ds-button--secondary {
		background-color: var(--button-secondary-bg);
		color: var(--button-secondary-color);
		border: 1px solid var(--button-secondary-border);

		img, svg {
			@include to-primary;
		}

		&:hover {
			background-color: var(--button-secondary-bg-hover);
			color: var(--button-secondary-color-hover);

			img, svg {
				@include to-white;
			}
		}

		&:active {
			background-color: var(--button-secondary-bg-hover);
			color: var(--button-secondary-color-hover);
			border: 1px solid var(--button-secondary-border-active);

			img, svg {
				@include to-white;
			}
		}

		&.ds-button--loading::before {
			border: 2px solid #E7E7E7;
			border-top-color: var(--primary-500);
		}
	}

	&.ds-button--ghost {
		background-color: var(--button-ghost-bg);
		color: var(--button-ghost-color);
		border: 1px solid var(--button-ghost-border);

		&:hover {
			background-color: var(--button-ghost-bg-hover);
			border: 1px solid var(--button-ghost-bg-hover);
		}

		&:active {
			background-color: var(--button-ghost-bg);
			border: 1px solid var(--button-ghost-border-active);
		}

		&.ds-button--loading::before {
			border: 2px solid #E7E7E7;
			border-top-color: var(--primary-500);
		}
	}

	&.ds-button--tertiary {
		background-color: var(--button-tertiary-bg);
		color: var(--button-tertiary-color);
		border: 1px solid var(--button-tertiary-border);

		&:not(.ds-button--iconOnly) img, &:not(.ds-button--iconOnly) svg {
			@include to-primary;
		}

		&:hover {
			background-color: var(--button-tertiary-bg-hover);
			color: var(--button-tertiary-color-hover);
			text-decoration: underline;
		}

		&:active {
			background-color: var(--button-tertiary-bg-hover);
			color: var(--button-tertiary-color-hover);
			text-decoration: underline;
			border: 1px solid var(--button-tertiary-border-active);
		}

		&.ds-button--loading::before {
			border: 2px solid #E7E7E7;
			border-top-color: var(--primary-500);
		}

		&.ds-button--iconOnly {
			border-color: #E1E1E1;

			&:hover {
				border-color: #000;

			}
		}
	}

	&.ds-button--darkTheme {
		background-color: var(--button-darktheme-bg);
		color: var(--button-darktheme-color);
		border: 1px solid var(--button-darktheme-border);

		img, svg {
			@include to-primary;
		}

		&:hover {
			background-color: var(--button-darktheme-bg-hover);
			color: var(--button-darktheme-color-hover);

			img, svg {
				@include to-white;
			}
		}

		&:active {
			background-color: var(--button-darktheme-bg-hover);
			color: var(--button-darktheme-color-hover);
			border: 1px solid var(--button-darktheme-border-active);

			img, svg {
				@include to-white;
			}
		}

		&.ds-button--loading::before {
			border: 2px solid #E7E7E7;
			border-top-color: #BC070E;
		}
	}
}
