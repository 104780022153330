@use '../../designSystem/common/sassVars' as *;
@use "../../designSystem/mixins/border-arrow" as *;

.navbar__userMenu {
	.navbar__userMenu__back {
		@media (min-width: $navbar-breakpoint-min) {
			display: none;
		}

		@media (max-width: $navbar-breakpoint-max) {
			display: block;
			position: relative;
			padding-left: 20px !important;
			margin-bottom: 40px;

			&::before {
				@include border-arrow(left, 14px, 8px);

				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -4px;
			}
		}

	}

	&.dropdownMenu {
		display: none;
		position: absolute;
		z-index: 2;
		cursor: default;
		text-align: left;
		font-size: 16px;
		padding: 20px;

		@media (max-width: $navbar-breakpoint-max) {
			width: 100%;
			top: 0;
			left: 0;
		}

		@media (min-width: $navbar-breakpoint-min) {
			background-color: #fff;
			border-radius: 4px;
			border: 1px solid var(--grey-400);
			width: 340px;
			right: 20px;
			top: 50px;

		}

		img {
			margin-bottom: 0;
			border-radius: 50%;
		}

		header {
			display: grid;
			grid-template-columns: 43px auto;
			column-gap: 12px;

			b {
				display: block;
			}
		}

		ul {
			border-top: 1px solid var(--grey-400);
			border-bottom: 1px solid var(--grey-400);
			padding: 20px 0;
			margin: 20px 0;

			li:not(:last-child) {
				margin-bottom: 20px;
			}

			a {
				display: block;
				background-repeat: no-repeat;
				background-position: center left;
				padding-left: 30px;

				&.ico__settings {
					background-image: url('../../../web/img/svg/usermenu-settings.svg');
				}

				&.ico__myStack {
					background-image: url('../../../web/img/svg/mystack_black.svg');
					background-size: 18px;
				}

				&.ico__cms {
					background-image: url('../../../web/img/svg/usermenu-cms.svg');
				}

				&.ico__adminMode {
					background-image: url('../../../web/img/svg/usermenu-admin.svg');
				}
			}
		}

		.signout {
			display: inline-block;
		}
	}

	.navbar--userMenuOn & {
		display: block;
	}
}
