@use '../../designSystem/common/sassVars' as *;

#saferGambling {
	background: var(--primary-500);
	position: relative;
	width: 100%;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--grey-300);
	align-items: center;
	column-gap: 20px;

	@media (min-width: $scr-lg-min) {
		padding: 10px 30px;
	}

	@media (max-width: 860px) {
		flex-direction: column;
	}

	.saferGambling__image {
		min-height: 20px;

		@media (max-width: $scr-xs) {
			img {
				width: auto;
			}
		}

		@media (min-width: $scr-xs-min) {
			display: flex;
			min-height: 30px;
		}
	}

	div {
		display: flex;
		align-items: end;
		gap: 10px 20px;

		@media (max-width: $scr-xs) {
			flex-wrap: wrap;
			justify-content: center;
		}

		img {
			display: block;
			max-height: 30px;
			max-width: 200px;

			@media (max-width: $scr-xs) {
				max-height: 20px;
			}
		}
	}

	p {
		font-size: 15px;
		line-height: 20px;
		margin: 0;
		color: #fff;

		a {
			color: #fff;
		}

		@media (max-width: 860px) {
			text-align: center;
			margin-bottom: 10px;
		}

		@media (max-width: $scr-xs) {
			font-size: 10px;
			line-height: 12px;
		}
	}
}

.site-br {
	#saferGambling {
		@media (max-width: 860px) {
			flex-direction: row;

			p {
				margin-bottom: 0;
			}
		}
	}
}
