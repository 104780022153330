@use "dark";
@use "sizes";
@use "alignment";

.ds-formCheckbox {
	display: grid;
	grid-template-columns: 20px auto;
	column-gap: 16px;

	small {
		font-size: 12px;
		line-height: 16px;
	}

	div {
		font-size: 16px;
		line-height: 16px;
	}

	input {
		appearance: none;
		background-color: #fff;
		margin: 2px 0 0;
		padding-bottom: 2px !important;
		width: 20px;
		height: 20px;
		border: solid 1px var(--grey-400);
		border-radius: var(--rad);
		display: grid;
		place-content: center;

		&:checked {
			background-color: #000;

			&::before {
				content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy43IiBoZWlnaHQ9IjkuOSI+CiAgPHBhdGggZD0iTTE0LjIgMjAuNGExIDEgMCAwIDAgLjMgMCAxIDEgMCAwIDAgLjQtLjNsNy45LThhMSAxIDAgMCAwIC4zLS42IDEgMSAwIDAgMC0uMy0uNyAxIDEgMCAwIDAtMS40IDBMMTQuMiAxOCAxMSAxNWExIDEgMCAwIDAtLjYtLjMuOS45IDAgMCAwLS43LjMgMSAxIDAgMCAwIDAgMS40bDMuOCAzLjhhMSAxIDAgMCAwIC4zLjIgMSAxIDAgMCAwIC40LjF6IiBkYXRhLW5hbWU9Ikdyb3VwIDk2MjkiIHN0eWxlPSJmaWxsOiNmZmYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05LjQgLTEwLjUpIi8+Cjwvc3ZnPg==');
			}
		}
	}

	&--disable {
		opacity: .5;
		pointer-events: none;
	}

	&--darkTheme {
		input, input:checked {
			background-color: transparent;
		}
	}
}
