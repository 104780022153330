@use "../common/sassVars" as *;

.ds-button--fullWidth {
	width: 100%;
}

@media (max-width: $scr-xs) {
	.ds-button--fullWidthMobile {
		width: 100%;
	}
}
