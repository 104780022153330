@use "../../common/sassVars" as *;
@use '../../mixins/bp' as *;
@use "../../exports/baseTextFormat";
@use "articleBase";
@use "ambassador";
@use "defaultArticle";
@use "sidebar";
@use "footer";
@use "roomNotify";
@use "darkTheme";

.ds-subscribe {
	padding: 24px;
	margin-bottom: var(--margin-l);
	border-radius: var(--rad);

	&__main {
		&__title {
			text-wrap: balance;

			@media (min-width: $scr-sm) {
				margin: 0 0 8px;
			}
		}

		&__text {
			@extend %base-text-format;

			text-wrap: balance;

		}

		&__form, &__form__group {
			display: grid;
			row-gap: 16px;
		}

		&__form__group--country {
			@include bp-mobile {
				grid-template-areas: "email" "country" "button";
				grid-template-columns: auto !important;
			}

			@include bp-not-mobile {
				grid-template-areas: "email email" "country button";
				grid-template-columns: 2fr 1fr;
			}

			.ds-formInput {
				grid-area: email;
			}

			.ds-subscribe__tomSelectWrapper {
				grid-area: country;
			}

			.ds-subscribe__button {
				grid-area: button;
			}
		}

		.ds-message {
			align-items: center;
			margin: 0;
		}
	}

	&--inlineFormDesktop .ds-subscribe__main__form__group {
		@media (min-width: $scr-sm-min) {
			grid-template-columns: 3fr 1fr;
			column-gap: 16px;
		}
	}
}
