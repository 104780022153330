%preloader {
	.preloader {
		position: absolute;
		inset: 0;
		background-color: rgb(255 255 255 / 70%);
		z-index: 9;
		opacity: 1;
		transition: 0.5s all;
	}

	.loaded {
		min-height: 0;

		.preloader {
			opacity: 0;
			z-index: -1;
		}
	}

	.lds-hourglass {
		display: inline-block;
		width: 64px;
		height: 64px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -32px;
		margin-top: -32px;
	}

	.lds-hourglass::after {
		content: " ";
		display: block;
		border-radius: 50%;
		width: 0;
		height: 0;
		margin: 6px;
		box-sizing: border-box;
		border: 26px solid var(--grey-300);
		border-color: var(--grey-300) transparent var(--grey-300) transparent;
		animation: lds-hourglass 1.2s infinite;
	}

	@keyframes lds-hourglass {
		0% {
			transform: rotate(0);
			animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}

		50% {
			transform: rotate(900deg);
			animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}

		100% {
			transform: rotate(1800deg);
		}
	}

}
