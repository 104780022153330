a {
	text-decoration: none;
	color: var(--primary-500);

	&:hover {
		color: var(--primary-600);
	}
}

a.black-link,
.black-link a {
	color: #000;

	&:hover {
		text-decoration: underline;
	}

	&.black-link--underline {
		text-decoration: underline;
	}
}
