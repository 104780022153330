@use "../exports/baseTextFormat";

ul.ds-ul,
ol.ds-ol {
	padding-left: 40px;

	@extend %base-text-format;

	li:not(:last-child) {
		margin-bottom: 5px;
	}
}

ul.ds-ul {
	list-style-type: disc;

	&--unstyled {
		list-style: none;
		padding-left: 0;
	}
}
