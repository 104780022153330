@use "../designSystem/common/sassVars" as *;

@media (min-width: $scr-md-min) {
	.monthPopular {
		li {
			padding: 15px;
			display: grid;
			grid-template-columns: 100px 1fr;
			column-gap: 15px;
			align-items: flex-start;

			&:not(:last-child) {
				border-bottom: 1px solid var(--grey-400);
			}

			img {
				vertical-align: top;
			}

			a:last-child {
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}

	}
}
