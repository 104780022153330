@use "../common/sassVars" as *;
@use '../mixins/close-button' as *;

.ds-roomReviewStickyBar {
	align-items: center;
	background-color: #fff;
	border-top: 1px solid var(--grey-400);
	bottom: 0;
	display: grid;
	grid-template-areas: "logo text" "cta cta";
	grid-template-columns: auto 1fr;
	left: 0;
	opacity: 0;
	padding: 15px;
	pointer-events: none;
	position: fixed;
	gap: 10px 15px;
	transform: translateY(130%);
	transition: transform .2s;
	width: 100%;
	will-change: transform;
	z-index: 5;

	@media (min-width: $scr-xs-min) and (max-width: $scr-md) {
		grid-template-areas: "logo text" "logo cta";
	}

	&.hasTerms {
		grid-template-areas: "logo text" "cta cta" "terms terms";

		@media (min-width: $scr-xs-min) and (max-width: $scr-md) {
			grid-template-areas: "logo text" "logo cta" "terms terms";
		}
	}

	@media (min-width: $scr-md-min) {
		border-top-left-radius: var(--rad);
		border-top-right-radius: var(--rad);
		padding: 0 20px;
		border-right: 1px solid var(--grey-400);
		grid-template-areas: "logo text cta";
		grid-template-columns: auto 1fr auto;

		&.hasTerms {
			grid-template-areas: "logo text cta" "logo terms cta";
		}
	}

	@media (max-width: $web-width) and (min-width: $scr-md-min) {
		width: calc(100% - 340px);
		left: 0;
	}

	@media (min-width: $web-width-min) {
		width: 990px;
		left: auto;
	}

	&.show {
		transform: translateY(0);
		opacity: 1;
		pointer-events: unset;
	}

	&__logo {
		grid-area: logo;
		display: grid;
		align-items: center;
		padding: 10px;
		background-color: #000;
		align-self: normal;

		img {
			max-height: 45px;
			vertical-align: top;
			max-width: 100px;
			width: 100%;

			@media (min-width: $scr-md-min) {
				max-width: 150px;
			}

			&:last-child {
				display: block;
			}

			&:first-child {
				display: none;
			}
		}


		@media (min-width: $scr-md-min) {
			padding: 10px 20px;
			margin-left: -20px;
			margin-top: -1px;
			border-top-left-radius: var(--rad);

			img:first-child {
				display: block;
			}

			img:last-child {
				display: none;
			}
		}
	}


	&__offer {
		grid-area: text;
		font-weight: 600;
		margin: 0;
		padding-right: 30px;
		line-height: 22px;

		@media (min-width: $scr-md-min) {
			font-size: 20px;
			font-weight: 400;
			line-height: 28px;
			margin: 10px 0;
		}
	}

	.tc_apply {
		vertical-align: 2px;
		margin-left: 5px;

		@media (min-width: $scr-md-min) {
			vertical-align: 4px;
		}
	}

	&__cta {
		grid-area: cta;
		align-self: center;

		@media (min-width: $scr-xs-min) and (max-width: $scr-md) {
			justify-self: flex-start;
		}
	}

	&__terms {
		color: var(--grey-700);
		margin: 0;

		@media (min-width: $scr-md-min) {
			margin: -10px 0 10px;
		}
	}


	&__close {
		@include close-button(38px, light);

		position: absolute;
		top: 0;
		right: 0;

		@media (min-width: $scr-md-min) {
			right: -20px;
			top: -20px;
		}
	}
}
