@use "../designSystem/common/sassVars" as *;

.virgutis.stickyFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    min-height: 50px;

    object {
        display: block;
        margin: 0 auto;
    }

    @media screen and (orientation:portrait) {
        * {
            width: 100%;
            max-width: 320px;
        }
    }

    @media (min-width: $scr-sm-min) {
        display: none !important
    }

    .close {
        top: -25px;
        right: 5px;
    }
}

.fullScreenMobile {
    background-color: #000;
    position: fixed;
    inset: 0;
    text-align: center;
    z-index: 100004;
    height: 100%;

    .mobilePopup {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translate(0%, -50%);
        height: 100%;
    }

    img,
    iframe {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 38px;
        height: 38px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        background: var(--primary-500);
        z-index: 1;
        font-weight: 600;
    }
}
