.navbar__search__results__item {
    display: grid;
    grid-template-columns: 46px auto;
    column-gap: 12px;
    color: var(--grey-700);
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &:hover,
    &:focus {
        color: #000;
        outline: none;

        .navbar__search__results__item__title {
            text-decoration: underline;
        }

        &::before {
            opacity: 1;
        }
    }

    div {
        b {
            color: #000;
        }

        .navbar__search__results__item__title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .navbar__search__results__item__type {
            display: block;
            font-size: 12px;
        }

    }

    img {
        justify-self: center;
        border-radius: var(--rad);
        background-color: var(--grey-200);
    }

}

.navbar__search__results__item--player {
    img {
        border-radius: 50%;
    }
}

.navbar__search__results__item--noImage {
    &::before {
        content: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiPjxwYXRoIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0ibTggMTEgNS01bTAgMEw4IDFtNSA1SDEiLz48L3N2Zz4=');
        width: 12px;
        height: 10px;
	    opacity: 0.5;
        position: relative;
        top: -5px;
        justify-self: center;
    }
}
