@use "../components/twitter";

.twitterWidget {
	header {
		background-color: var(--twitter-color);
		display: grid;
		column-gap: 10px;
		grid-template-columns: 45px auto auto;
		align-items: center;
		margin: -1px;
		border-top-left-radius: var(--rad);
		border-top-right-radius: var(--rad);

		h3 {
			color: #fff;
		}

		svg {
			fill: #fff;
			width: 40px;
			height: 32px;
			margin: -6px 0;
		}

		.virgutis {
			background-color: transparent !important;
			width: 138px;

			&:empty {
				display: none;
			}
		}
	}

	.tweeter {
		li {
			padding: 15px;
			margin: 0;

		}

		.tweet__text a {
			color: var(--twitter-color);
		}
	}

	footer a {
		background-color: var(--twitter-color);
		color: #fff !important;
		margin: -1px;
		border-bottom-left-radius: var(--rad);
		border-bottom-right-radius: var(--rad);
	}
}
