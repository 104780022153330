@use '../designSystem/common/sassVars' as *;
@use "../designSystem/mixins/border-arrow" as *;
@use "../designSystem/mixins/overlay" as *;
@use "../designSystem/mixins/rounded-card" as *;

.subdomainDropdown {
    position: relative;

    button {
        display: flex;
        align-items: center;
        column-gap: 10px;
        position: relative;
        padding-right: 20px;

        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -2px;
        }

        img {
            width: 22px;
            vertical-align: top;
        }
    }

    ul {
        display: none;
    }

    &.on ul {
        display: block;
        position: absolute;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 0;
        width: 264px;

        li:not(:last-child) {
            margin-bottom: 16px;
        }

        @media (min-width: $scr-sm-min) {
            right: -5px;
        }

        li.active a::before {
            content: '';
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTMuNzcuMjNjLjMuMy4zLjc4IDAgMS4wOEw1LjIyIDkuNzdjLS4zLjMtLjguMy0xLjEgMEwuMjIgNS45M2EuNzYuNzYgMCAwIDEgMC0xLjA5Yy4zMS0uMy44LS4zIDEuMSAwbDMuMzUgMy4zIDgtNy45MWMuMy0uMy44LS4zIDEuMSAwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
            position: absolute;
            top: 7px;
            right: 20px;
            width: 14px;
            height: 10px;
        }

        a {
            color: #000;
            display: grid;
            grid-template-columns: 24px auto;
            gap: 0 12px;
            line-height: 24px;
            padding: 0 34px 0 20px;
            position: relative;
            white-space: nowrap;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }

            @media (max-width: $navbar-breakpoint-max) {
                font-size: 16px;
            }
        }
    }

    &.subdomainDropdown--header--desktop {
        @media (max-width: $navbar-breakpoint-max) {
            visibility: hidden;
            position: fixed;
            pointer-events: none;
            width: 82px;
        }

        @media (min-width: $navbar-breakpoint-min) {
            button::after {
                @include border-arrow(bottom, 12px, 6px, black);
            }

            &.on {
                button::after {
                    transform: rotate(180deg);
                }

                ul {
                    top: 30px;
                    z-index: 2;
                }
            }

            ul {
                @include rounded-card;
            }
        }
    }

    &.subdomainDropdown--header--mobile {
        @media (min-width: $navbar-breakpoint-min) {
            display: none;
        }

        @media (max-width: $navbar-breakpoint-max) {
            position: fixed;
            bottom: 0;
            right: 0;
            z-index: 1;
            background-color: #fff;
            border-top: 1px solid var(--grey-400);
            width: 100%;

            @media (min-width: 501px) {
                max-width: var(--mobile-menu-max-width);
            }

            button {
                width: 100%;
                padding: 16px 20px;
                font-size: 16px;

                &::after {
                    content: normal;
                }

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            &.on ul {
                position: fixed;
                border-top: 1px solid var(--grey-400);
                width: 100%;
                bottom: 0;
                top: auto;
                border-radius: 0;
                right: 0;

                @include overlay;

                @media (min-width: 501px) {
                    max-width: var(--mobile-menu-max-width);
                }
            }
        }
    }

    &.subdomainDropdown--footer {
        button::after {
            @include border-arrow(bottom, 12px, 6px, white);
        }

        .subdomainDropdown__global {
            filter: brightness(0) invert(1);
        }

        &.on ul {
            bottom: -20px;
        }
    }

}
