@media (min-width: 1301px) {
	.hasWallpaper {
		background-repeat: no-repeat;
		background-position: top center;
		background-attachment: fixed;
		cursor: pointer;

		.virgutis--top, .virgutis--topMobile {
			display: none !important;
		}

		body {
			margin-top: 130px;
			cursor: auto;
		}
	}
}

@media (max-width: 1300px) {
	.hasWallpaper {
		background-image: none !important;
		background-color: var(--grey-400) !important;
	}
}
