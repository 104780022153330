@use '../designSystem/common/sassVars' as *;

.tvON {
	body {
		max-width: none;
	}

	.event__post__body .ds-table-mobileFriendly,
	.event__post__body .table-responsive {
		width: calc(100vw - 90px);

		@media (min-width: $scr-lg-min) {
			max-width: calc(100vw - 120px);
		}

	}

	&.hasWallpaper {
		background: var(--grey-400) !important;

		body {
			margin-top: 0 !important;
		}
	}

	.navbar {
		border-bottom: 1px solid var(--grey-200);
	}

	.navbar__logo {
		display: flex;
		align-items: center;

		&::after {
			color: var(--red-500);
			content: "Live Reporting";
			font-size: 22px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}



	.navbar__toggle,
	.liveNowTop,
	.navbar__mobile,
	.virgutis,
	#bnChildContainer,
	.siteFooter,
	#sideBar,
	.breadcrumb,
	.ds-socialDropdown,
	.subscribe,
	.videos {
		display: none !important;
	}

	#container:not(.wide) {
		grid-template-areas: "main";
		grid-template-columns: 1fr;
	}

	.expandArticleContainer {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	.navbar__logo a {
		background-size: 200px 30px;
		display: inline-block;
		width: 240px;
	}

	.pswp button,
	.navbar__logo a,
	.event__mystack__promo,
	.event__header h1 a,
	.event__nav--events a:not([data-turbo="true"]) {
		pointer-events: none;
	}


	#lrFeed {
		max-width: none;

		article,
		.event__topChipCounts,
		.videoContainer,
		.event__infoTable,
		.expandArticleContainer:not(.seatDraw),
		#liveStreamPage {
			a,
			iframe {
				color: #000;
				pointer-events: none;
				text-decoration: none;
			}

		}

		.event__topChipCounts {
			@media (max-width: 1380px) and (min-width: $scr-lg-min) {
				max-width: 100%;
			}
		}
	}


	.stickyIconNavBar.isSticky {
		max-width: none;
		left: auto;
		right: auto;
		width: calc(100vw - 40px);

		@media (min-width: $scr-lg-min) {
			width: calc(100vw - 60px);
		}
	}
}
