@use "../../designSystem/common/sassVars" as *;

.playerWidget__tinyCard {
	background-color: #fff;
	color: #000;
	display: grid;
	border-radius: 4px;
	grid-template-columns: 46px auto;
	align-items: center;
	column-gap: 12px;
	line-height: 20px;
	overflow: hidden;

	@media (max-width: $scr-xlg) and (min-width: $scr-md-min) {
		&.wrappable {
			grid-template-columns: auto;
			grid-template-rows: 46px auto;
			text-align: center;
			place-items: flex-start center;
			row-gap: 5px;

			img {
				max-height: 46px;
			}
		}
	}

	&:not(.playerWidget__tinyCard--plain) {
		padding: 7px;
	}

	.country {
		position: absolute;
		bottom: 5px;
		right: -2px;
		box-shadow: 0 0 0 1px var(--flag-outline);
	}

	&:hover {
		color: #000;
	}

	&:not(.playerWidget__tinyCard--plain):hover {
		box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
	}

	figure {
		position: relative;
		margin: 0;

		img {
			vertical-align: top;
			max-width: none;

			&.country {
				box-shadow: 0 0 0 1px var(--flag-outline);
			}
		}

		.photo {
			border-radius: 50%;
		}
	}
}
