@use '../designSystem/common/sassVars' as *;

html,
body {
	min-height: 100%;
}

body {
	margin: 0 auto;
	max-width: $web-width;
	min-width: 320px;
}

html {
	background-color: var(--grey-400)
}

#container {
	background: #fff;
	padding: 20px;

	@media (min-width: $scr-lg-min) {
		padding: var(--grid-side-padding-desktop);
	}

	@media (min-width: $scr-md-min) {
		display: grid;

		#main {
			grid-area: main;
			position: relative;
		}

		#sideBar {
			grid-area: sidebar;
		}
	}

	&:not(.wide) {
		column-gap: 20px;

		@media (min-width: $scr-md-min) {
			grid-template-areas: 'main sidebar';
			grid-template-columns: 1fr 300px;
		}

		@media (min-width: $scr-lg-min) {
			column-gap: var(--grid-side-padding-desktop);
		}
	}

	&.wide {
		@media (min-width: $scr-md-min) {
			grid-template-areas: 'main';
		}
	}
}
