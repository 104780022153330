.siteFooter__company {
    grid-area: company;

    &>p {
        line-height: 20px;
        margin: 0;
    }

    &__igb {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 15px;
        font-size: 12px;
        align-items: center;
        margin-top: 30px;

        b {
            display: block;
            text-transform: uppercase;
        }

        p {
            margin: 0;

            i {
                font-size: 16px;
                vertical-align: -2px;
            }
        }
    }
}
