@use "../designSystem/exports/baseTextFormat";

.pn-error {
    color: var(--warning-color);
    border: 1px solid var(--warning-color);
    border-radius: var(--rad);
    font-weight: 600;
    padding: 9px 15px;
    text-align: left;
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 10px;
    align-items: center;
    width: 100%;

    &::before {
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4wMjMiIGhlaWdodD0iMjIuNjc1Ij48cGF0aCBmaWxsPSIjZmZjOWNiIiBzdHJva2U9IiNlZTFjMjQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgZD0iTS44MSAxOC4zMjRsOS4zMjMtMTYuMzU1YTIuNjYgMi42NiAwIDAxNC43NTggMGw5LjMyMyAxNi4zNTVhMi42NjEgMi42NjEgMCAwMS0yLjM3OSAzLjg1MUgzLjE4OUEyLjY2IDIuNjYgMCAwMS44MSAxOC4zMjR6Ii8+PHBhdGggZmlsbD0iI2VlMWMyNCIgZD0iTTExLjYzNCAxNy44N3EwLS45ODkuODczLS45OWEuODM1LjgzNSAwIDAxLjY1Mi4yNTUgMS4wNjUgMS4wNjUgMCAwMS4yMjkuNzM1IDEuMDQyIDEuMDQyIDAgMDEtLjIzMi43MjQuODI3LjgyNyAwIDAxLS42NDguMjU4Ljg4Ni44ODYgMCAwMS0uNjI2LS4yMjkuOTk0Ljk5NCAwIDAxLS4yNDgtLjc1M3ptMS4yNjctMi4xNjJoLS43NjVMMTEuNzY2IDhoMS41MDZ6Ii8+PC9zdmc+');

    }
}

.error,
.success,
.tip {
    @extend %base-text-format;

    margin-bottom: 20px;
    text-align: center;
    padding: 8px 20px;

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.error,
.tip {
    background-color: var(--red-500);
    color: #fff;
    font-weight: 600;
}

.success {
    background-color: rgba(#1ed560, 20%);
    color: #000;
}
