@use "../common/sassVars" as *;

h1,
h2,
h3,
h4 {
	font-weight: 600;
}

h1 {
	font-size: var(--h1-size-m);
	line-height: var(--h1-line-height-m);
	margin: 0 0 var(--margin-heading);

	@media (min-width: $scr-xs-min) {
		font-size: var(--h1-size-d);
		line-height: var(--h1-line-height-d);
	}
}

h2 {
	font-size: var(--h2-size-m);
	line-height: var(--h2-line-height-m);
	margin: 0 0 var(--margin-heading);

	@media (min-width: $scr-xs-min) {
		font-size: var(--h2-size-d);
		line-height: var(--h2-line-height-d);
	}

	&.ds-headlineShrinked {
		font-size: var(--h2-size-m);
		line-height: var(--h2-line-height);
		margin: 0 0 var(--margin-heading);
	}
}

h3 {
	font-size: var(--h3-size-m);
	line-height: var(--h3-line-height-m);
	margin: 0 0 var(--margin-heading);

	@media (min-width: $scr-xs-min) {
		font-size: var(--h3-size-d);
		line-height: var(--h3-line-height-d);
	}
}

h4 {
	font-size: var(--h4-size-m);
	line-height: var(--h4-line-height-m);
	margin: 0 0 var(--margin-heading);

	@media (min-width: $scr-xs-min) {
		font-size: var(--h4-size-d);
		line-height: var(--h4-line-height-d);
	}
}
