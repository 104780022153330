@use "../../../players/components/tinyPlayerCard";
@use '../../../designSystem/common/sassVars' as *;

.submenu__players {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 10px;

	.playerWidget__tinyCard {
		border: 1px solid var(--grey-500);
		grid-template-columns: 39px auto;

		&:hover {
			border-color: #000;
			text-decoration: none !important;
			box-shadow: none;
		}

		@media (max-width: $scr-xlg) and (min-width: $scr-md-min) {
			grid-template-rows: 39px auto;

			img {
				max-height: 39px;
			}
		}

	}
}
