@use '../../../components/videoLength';
@use '../../../designSystem/exports/videoPlayButton';

.length {
	transform: scale(0.7);
	transform-origin: right bottom;
}

.submenu__articleList {
	display: grid;
	column-gap: 10px;
	grid-template-columns: repeat(3, 1fr);

	figure {
		--play-button-size: 26px;

		display: block;
		margin-bottom: 5px;
		position: relative;
		width: 100%;

		&::after {
			content: '';
			display: block;
			padding-top: calc(100 / 154 * 100%);
		}

		.videoPlayButton {
			@extend %video-play-button;
		}

	}

	img {
		background: var(--grey-100);
		height: 100%;
		position: absolute;
		inset: 0;
		vertical-align: top;
		width: 100%;
		object-fit: cover;
	}
}

.submenu--rooms,
.submenu--rules,
.submenu--casino {
	.submenu__articleList figure::after {
		padding-top: calc(628 / 1200 * 100%);
	}
}
