@use '../../../designSystem/common/sassVars' as *;
@use "./icon";
@use "./dropdown";
@use "./form/base";
@use "./skeleton";
@use "../../../designSystem/mixins/overlay";

.navbar__search__wrapper {
	grid-area: navbar__search;
	height: var(--navbar-height);
	display: flex;
	align-items: center;

	@media (min-width: $navbar-breakpoint-min) {
		position: relative;
		margin-right: calc(
			var(--featuread-width) + var(--grid-side-padding-desktop) + var(--navbar-desktop-features-gap));
	}
}

@media (min-width: $navbar-breakpoint-min) {
	.navbar__search__preloader.desktopHide {
		display: none;
	}
}

.activeSearch {
	@media (max-width: $navbar-breakpoint-max) {
		overflow: hidden;
	}

	#container, .navbar__menu, .liveNowTop {
		filter: brightness(0.7);
	}
}
