@use '../../../designSystem/common/sassVars' as *;

.navbar__search__spyGlass {
    @media (min-width: $navbar-breakpoint-min) {
        display: none;
    }

    @media (max-width: $navbar-breakpoint-max) {
        display: flex;
        justify-content: center;

        span {
            display: none;
        }

        img.navbar__ico {
            width: 20px;
            height: 20px;
            margin: 0;
        }
    }
}
