@use "../common/sassVars" as *;

:root {
	--border-color: var(--grey-300);
	--deep-sky-blue: #00b9ff;
	--facebook-color: #1b74e4;
	--flag-outline: var(--grey-200);
	--font-weight: 600;
	--green-500: #1ed560;
	--green-600: #11be4f;
	--green-700: #01af40;
	--grey-100: #f5f5f5;
	--grey-200: #eee;
	--grey-300: #e5e5e5;
	--grey-400: #e1e1e1;
	--grey-500: #ccc;
	--grey-600: #a3a3a3;
	--grey-700: #767676;
	--grey-800: #252525;
	--margin-l: 30px;
	--margin-m: 24px;
	--margin-heading: 20px;
	--mystack-color: #0091ea;
	--orange-500: #fe862e;
	--orange-600: #f47519;
	--primary-100: #e6f0fa;
	--primary-200: #a4c6e8;
	--primary-500: #0069D0;
	--primary-600: #0080ff;
	--primary-700: #0060ff;
	--primary-buttontext: #fff;
	--rad: 4px;
	--red-200: #fce8e9;
	--red-500: #e01b22;
	--red-600: #ee1c24;
	--red-700: #bc070e;
	--room-header-bg: #252525;
	--steps-color: #008000;
	--table-text-size: 14px;
	--text-line-height-d: 26px;
	--text-line-height-m: 24px;
	--text-size-d: 16px;
	--text-size-m: 14px;
	--twitter-color: #000;
	--warning-color: var(--red-600);
	--yellow: #ffd800;
	--info: #f5f5f5;
	--info-border: #788FA6;
	--warning: #FDF0E6;
	--warning-border: #E76500;
	--error: #F8E6E7;
	--error-border: #BC070E;
	--success: #E6F2E6;
	--success-border: #008000;
	--h1-size-d: 32px;
	--h1-size-m: 26px;
	--h2-size-d: 24px;
	--h2-size-m: 22px;
	--h3-size-d: 20px;
	--h3-size-m: 18px;
	--h4-size-d: 16px;
	--h4-size-m: 14px;
	--h1-line-height-d: 42px;
	--h1-line-height-m: 36px;
	--h2-line-height-d: 34px;
	--h2-line-height-m: 32px;
	--h3-line-height-d: 30px;
	--h3-line-height-m: 28px;
	--h4-line-height-d: 26px;
	--h4-line-height-m: 24px;
	--grid-side-padding-desktop: 30px;
	--grid-side-padding-mobile: 20px;
	--navbar-height: 60px;
	--navbar-desktop-features-gap: 30px;
	--mobile-menu-max-width: 400px;

	@media (min-width: $scr-xs-min) {
		--margin-l: 40px;
		--margin-m: 20px;
	}
}
