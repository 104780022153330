.submenu__rooms {
	.articleConversionBanner {
		margin: 0;
		background-color: transparent;

		.conversionBanner {
			column-gap: 10px;

			a {
				grid: "logo title" 45px
					"text text" auto
					"terms terms" auto
					"cta cta" 1fr / 40px 1fr !important;
				background-color: #fff !important;
				border-radius: 4px;
				border: 1px solid var(--grey-400);
				row-gap: 10px;

				&:hover {
					border: 1px solid #000;
				}

				span {
					margin-top: 0;
					align-self: flex-end;
				}

				strong span {
					font-size: 12px;
					font-weight: 600;
					display: block;
					margin: 0 0 3px;
					line-height: 14px;
				}

				p {
					font-size: 10px;
					font-weight: 400;
					line-height: 13px;
					min-height: 26px;
				}

				small {
					font-size: 8px;
					margin: 0;
				}

				.terms {
					--term-color: var(--grey-700);
				}
				
			}

			img {
				max-height: 40px;
				max-width: 40px;
				height: auto;
				min-width: auto;

			}
		}
	}

}
