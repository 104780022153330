.submenu__upcomingTournaments {
	display: grid;
	column-gap: 10px;
	grid-template-columns: repeat(3, 1fr);

	figure {
		display: flex;
		margin-bottom: 5px;
		justify-content: center;
		align-items: center;
		height: 105px;
	}

	img {
		max-height: 80px;
		max-width: 100px;
	}

	span {
		height: 44px;
		line-height: 22px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;

	}

	p {
		font-size: 13px;
		color: var(--grey-700);
	}
}