@use "dark";
@use '../../common/sassVars' as *;

@mixin message($bg-color, $border-color, $icon-url) {
	background-color: $bg-color;
	border-color: $border-color;
	border-radius: var(--rad);
	display: flex;
	column-gap: 16px;

	&::before {
		content: url('#{$icon-url}');
	}
}

.ds-message {
	font-size: 10px;
	line-height: 14px;
	margin-bottom: 20px;
	padding: 16px;
	border-left-width: 4px;
	border-left-style: solid;

	a {
		color: #000;
		text-decoration: underline;
		font-size: inherit !important;
	}

	@media (min-width: $scr-sm-min) {
		margin-bottom: 28px;
		padding: 20px;
	}

	&--info {
		@include message(var(--info), var(--info-border), 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9IiM3ODhGQTYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xMiAyMmM1LjUyMyAwIDEwLTQuNDc3IDEwLTEwUzE3LjUyMyAyIDEyIDIgMiA2LjQ3NyAyIDEyczQuNDc3IDEwIDEwIDEwWk0xMiAxNnYtNE0xMiA4aC4wMSIvPjwvc3ZnPgo=');
	}

	&--warning {
		@include message(var(--warning), var(--warning-border), 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9IiNFNzY1MDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xMC4yOSAzLjg2IDEuODIgMThhMiAyIDAgMCAwIDEuNzEgM2gxNi45NGEyIDIgMCAwIDAgMS43MS0zTDEzLjcxIDMuODZhMiAyIDAgMCAwLTMuNDIgMFpNMTIgOXY0TTEyIDE3aC4wMSIvPjwvc3ZnPgo=');
	}

	&--error {
		@include message(var(--error), var(--error-border), 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9IiNCQzA3MEUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xMiAyMmM1LjUyMyAwIDEwLTQuNDc3IDEwLTEwUzE3LjUyMyAyIDEyIDIgMiA2LjQ3NyAyIDEyczQuNDc3IDEwIDEwIDEwWk0xMiA4djRNMTIgMTZoLjAxIi8+PC9zdmc+Cg==');
	}

	&--success {
		@include message(var(--success), var(--success-border), 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9ImdyZWVuIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMTIgMjJjNS41IDAgMTAtNC41IDEwLTEwUzE3LjUgMiAxMiAyIDIgNi41IDIgMTJzNC41IDEwIDEwIDEwWiIvPjxwYXRoIHN0cm9rZT0iZ3JlZW4iIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Im05IDEyLjQ1IDEuOTUgMS45NUwxNS41IDEwIi8+PC9zdmc+Cg==');
	}
}
