@use '../../../../designSystem/common/sassVars' as *;

.navbar__search__results__offer {
    @media (min-width: $navbar-breakpoint-min) {
        margin-bottom: 24px;
    }

    b {
        font-weight: 400;
        color: var(--grey-700);
        display: block;
        margin-bottom: 20px;
    }

    ul {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        a {
            display: flex;
            column-gap: 12px;
            line-height: 22px;

            &::before {
                content: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiPjxwYXRoIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0ibTggMTEgNS01bTAgMEw4IDFtNSA1SDEiLz48L3N2Zz4=');
                width: 12px;
                height: 10px;
                opacity: 0.5;
            }

            &:hover {
                text-decoration: underline;
            }

            &:hover::before {
                opacity: 1;
            }

        }

    }
}
