@use '../../designSystem/common/sassVars' as *;
@use "../../designSystem/mixins/border-arrow" as *;
@use "../../designSystem/mixins/triangle" as *;

.liveNowTop {
	grid-area: navbar__live;
	z-index: 2;

	.activeMenu & {
		z-index: 0;
	}

	@media (max-width: $navbar-breakpoint-max) {
		&__label {
			display: grid;
			column-gap: 4px;
			grid-template-columns: auto 14px;
			border-radius: 15px;
			line-height: 14px;
			text-transform: uppercase;
			font-size: 10px;
			font-weight: 700;
			padding: 4px 4px 4px 8px;
			position: relative;
			border: 1px solid var(--red-500);
			background-color: #fff;
			margin-right: 20px;

			a {
				color: var(--red-500);
				pointer-events: none;
			}

			b {
				background-color: var(--red-500);
				border-radius: 50%;
				line-height: 14px;
				color: #fff;
				text-align: center;
				font-size: 10px;
			}
		}

		&__list {
			pointer-events: none;
			transform: scaleY(.5);
			transform-origin: top;
			position: fixed;
			top: var(--navbar-height);
			width: 100%;
			left: 0;
			opacity: 0;

			a,
			.title {
				color: inherit;
				display: flex;
				padding: 5px 20px;
				align-items: center;
				justify-content: space-between;
			}

			&>li {
				border-bottom: 1px solid rgb(255 255 255 / 50%);

				&>.title {
					font-weight: 600;
					min-height: 70px;
				}

				&>a.title {
					column-gap: 10px;

					b {
						flex: 1 0 0;
					}

					&::after {
						@include border-arrow(right, 14px, 8px, white);
					}
				}

				&>b.title {
					&::after {
						@include border-arrow(bottom, 14px, 8px, white);
					}
				}

				&:last-child {
					border-bottom: 0;
				}

				ul {
					display: none;
				}

				&.on {
					&>b.title::after {
						transform: rotate(180deg);
					}

					ul {
						display: block;
						padding-bottom: 10px;


						a {
							column-gap: 20px;
							padding-left: 30px;
							position: relative;

							&::before {
								@include triangle(right, 5px, 1, var(--primary-500));

								content: '';
								position: absolute;
								left: 20px;
								top: 13px;
							}

						}

						.streamButton {
							align-self: flex-start;
							top: 2px;
						}
					}
				}
			}
		}

		&.open {
			.liveNowTop {
				&__label::after {
					content: "";
					border-bottom: 0;
					border-left: 9px solid transparent;
					border-right: 9px solid transparent;
					border-top: 6px solid var(--red-500);
					bottom: -6px;
					height: 0;
					left: 50%;
					position: absolute;
					width: 0;
					transform: translateX(-50%);
				}

				&__list {
					pointer-events: all;
					overscroll-behavior: contain;
					background-color: #000;
					color: #fff;
					font-size: 16px;
					line-height: 24px;
					max-height: 80vh;
					overflow-y: auto;
					opacity: 1;
					transform: scaleY(1);

				}
			}
		}

	}

	@media (min-width: $navbar-breakpoint-min) {
		background-color: #000;
		display: grid;
		line-height: 34px;
		column-gap: 20px;
		grid-template-columns: auto 1fr;

		&__label {
			background-color: var(--red-500);
			padding: 0 15px 0 var(--grid-side-padding-desktop);
			text-transform: uppercase;
			position: relative;

			a {
				white-space: nowrap;
			}

			&::after {
				border-bottom: 17px solid transparent;
				border-left: 10px solid var(--red-500);
				border-top: 17px solid transparent;
				content: "";
				height: 0;
				position: absolute;
				right: -10px;
				top: 0;
				width: 0;
			}

			b {
				display: none;
			}
		}

		&__list {
			display: flex;
			max-width: 1000px;

			&>li {
				&>.title {
					padding-left: 15px;
					padding-right: 15px;
					white-space: nowrap;
				}

				&>a {
					display: flex;
					column-gap: 8px;
					align-items: center;
				}

				&>b.title {
					cursor: pointer;
					display: flex;
					column-gap: 8px;
					align-items: center;

					&::after {
						@include border-arrow(bottom, 10px, 6px, white);

						position: relative;
						top: 1px;
					}
				}

			}


			.hasChilds {
				position: relative;

				ul {
					display: none;
				}

				&.on {
					b.title::after {
						transform: rotate(180deg);
						top: 2px;
					}

					ul {
						display: block;
						position: absolute;
						top: 100%;
						left: 0;
						background-color: #000;
						z-index: 11;
						padding: 15px 0;

						a {
							padding: 5px 20px 5px 15px;
							line-height: 20px;
							column-gap: 20px;
							padding-left: 30px;
							position: relative;
							display: flex;
							align-items: center;
							width: 300px;
							justify-content: space-between;

							&::before {
								@include triangle(right, 5px, 1, var(--primary-500));

								content: '';
								position: absolute;
								left: 15px;
								top: 10px;
							}

						}

						.streamButton {
							align-self: flex-start;
							top: 2px;
						}
					}

					&.more ul {
						left: auto;
						right: 0;
					}

				}
			}

		}

		a,
		b {
			color: rgb(255 255 255 / 100%);
			font-weight: 600;

			&:hover {
				color: rgb(255 255 255 / 80%);
			}
		}

	}

	@media (max-width: $scr-xs) {
		&__label a span {
			display: none;
		}
	}

	@media (max-width: 350px) {
		&__label {
			max-width: 60px;
			padding: 5px 10px;
			display: block;

			a {
				line-height: 10px;
				display: block;
			}

			b {
				display: none;
			}
		}
	}

	#liveNowTop__list__viewAll {
		a {
			b {
				color: var(--primary-500);
			}
		}
	}


	.streamButton {
		color: var(--red-500);
		border: 1px solid currentcolor;
		border-radius: 4px;
		font-size: 12px;
		padding: 0 6px;
		line-height: 21px;
		font-weight: 600;
		position: relative;
		display: inline-flex;
		align-items: center;
		column-gap: 10px;

		&:hover {
			filter: brightness(1.5);
		}

		&::after {
			@include triangle(left, 5px, 1, currentcolor);

			content: '';
			position: absolute;
			left: 15px;
		}

		&::before {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background-color: currentcolor;
		}
	}

	.hasChilds.hasEventStream {
		padding-left: 8px;
		position: relative;

		@media (max-width: $navbar-breakpoint-max) {
			padding-left: 18px;

			.title {
				padding-left: 14px;
			}
		}

		&::before {
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			background-color: var(--red-500);
			border-radius: 50%;
			top: 14px;

			@media (max-width: $navbar-breakpoint-max) {
				top: 33px;
			}
		}
	}

}
