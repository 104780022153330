@use "../designSystem/common/sassVars" as *;
@use "twitterWidget";
@use "mostPopuplarUSA";
@use "editorChoice";

#sideBar {
	display: none;

	@media (min-width: $scr-md-min) {
		display: block;

		&>* {
			margin-bottom: 30px;
		}

	}
}
