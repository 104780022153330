@use '../designSystem/common/sassVars' as *;

.tcApplyPopup {
	padding: 40px;

	@media (max-width: $scr-xs){
		padding: 20px;
	}

	ul {
		padding-left: 20px;
		list-style-type: disc;
		margin-bottom: 40px;

		@media (max-width: $scr-xs){
			margin-bottom: 20px;
		}
	}

	h2 {
		text-align: center;
	}

	p, li {
		font-size: 14px;
		line-height: 22px;

		@media (min-width: $scr-xs-min){
			font-size: 16px;
			line-height: 26px;
		}
	}

	p {
		margin-bottom: 22px;
	}
}
