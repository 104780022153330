@use "popup";
@use "stickyFooter";
@use "mobileScroll";
@use "wallpaper";
@use "../designSystem/mixins/cross" as *;
@use "../designSystem/common/sassVars" as *;


// Hide 3rd part generated iframes
body footer~iframe:not(.modalWindow),
body footer~img,
.novirgutis .virgutis {
	display: none !important;
}

.virgutis {
	background: var(--grey-100);

	img,
	iframe {
		display: block;
		margin: 0 auto;
	}

	@media (min-width: 600px) {
		&[data-max-width="600"] {
			display: none !important
		}
	}

	@media (min-width: $scr-md-min) {
		&--videoFirst {
			margin-top: -40px;
		}
	}

	.videoContainer & {
		background-clip: padding-box;
	}

	article & {
		clear: both
	}

	.article & {
		@media (min-width: 501px) {
			padding: 5px !important;
		}

		@media (min-width: 1141px) {
			margin-left: -14px;
			margin-right: -14px;
		}

		@media (max-width: 500px) {
			padding: 0 !important;
		}
	}

	&--close {
		position: relative;

		.close {
			position: absolute;
			z-index: 1;
			cursor: pointer;
			background-color: #000;
			border-radius: 50%;
			border: 0 none;
			padding: 0;
			width: 20px;
			height: 20px;

			&::after {
				content: '';

				@include cross(#fff, 14px, 1px);

				margin: 0 0 0 3px;
				display: block;
			}
		}
	}

	#sideBar & {
		background-color: #f2f2f2;
	}
}


#main {
	.virgutis:not([data-zone="sponsored:post"]) {
		text-align: center;
		margin-bottom: 20px;
	}


}

// Rich Media banners container
#bnChildContainer {
	height: 0;

	img {
		display: block;
		margin: 0 auto;
	}
}

.virgutis--rectangle {
	min-height: 300px;
}

.virgutis--topMobile {
	--aspect-ratio-w: 320;
	--aspect-ratio-h: 50;
}

.virgutis--leaderboard,
.virgutis--top {
	max-width: 728px;

	--aspect-ratio-w: 728;
	--aspect-ratio-h: 90;

	@media (max-width: $scr-xs) {
		&[data-mzone] {
			--aspect-ratio-w: 300;
			--aspect-ratio-h: 100;
		}
	}
}

.virgutis--leaderboard:not(.scrolled-in-article),
.virgutis--top,
.virgutis--topMobile {
	position: relative;
	margin-left: auto;
	margin-right: auto;

	&::before {
		content: '';
		display: block;
		padding-top: calc(var(--aspect-ratio-h) / var(--aspect-ratio-w) * 100%);

	}

	a,
	a img,
	iframe {
		position: absolute;
		inset: 0;
		height: 100%;
		width: 100%;
	}
}
