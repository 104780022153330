@use "../designSystem/exports/iconTooltip";
@use "../components/export/uktoprooms_tc";

.topRooms {
	&__availableCountry {
		margin-top: 10px;

		img {
			vertical-align: -2px;
			box-shadow: 0 0 0 1px var(--flag-outline);
		}
	}

	&__list {
		&>div {
			display: grid;
			grid-template-areas: "logo title title title"
			"logo bonus bonus bonus"
			"logo minDeposit tc cta"
			"term term term term";
			grid-template-columns: 50px 1fr auto auto;
			column-gap: 8px;
			padding: 8px;
			position: relative;

			&:not(:last-child) {
				border-bottom: 1px solid var(--grey-400);
			}
		}

		&__logo {
			grid-area: logo;
			display: grid;
			align-items: center;
			text-align: center;

			img {
				vertical-align: top;
			}
		}

		&__title {
			grid-area: title;
			margin: 0;
			font-size: 16px;
			line-height: 26px;
			font-weight: 600;
		}

		&__bonus {
			grid-area: bonus;
			margin: 3px 0 10px;
			line-height: 18px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			height: 36px;
		}



		&__minDeposit {
			font-size: 10px;
			grid-area: minDeposit;

			b {
				font-size: 13px;
			}
		}

		.tc_apply {
			grid-area: tc;
			border-color: var(--primary-500);
			color: var(--primary-500);

			&:hover {
				background-color: var(--primary-500);
				color: #fff;
			}
		}

		.topRooms__list__button {
			grid-area: cta;
		}

		.topRooms__list__button,
		.tc_apply {
			align-self: center;
			margin-bottom: 4px;
			position: relative;
			top: -3px;
		}

		.award {
			position: absolute;
			top: 5px;
			right: 5px;

			@extend %icon-tool-tip;

		}

		.terms {
			grid-area: term;
			padding: 5px 5px 0;
			font-size: 8px;
		}
	}
}

.topRooms__ukTC {
	@extend %top-rooms-uk-tc;
}
