.submenu__logosList {
	gap: 10px;

	&--deposit {
		display: flex;

		a {
			width: 54px;
			padding: 0 2px;
		}

		img {
			max-height: 35px;
		}
	}

	a {
		background-color: #fff;
		border: 1px solid var(--grey-400);
		border-radius: 4px;
		row-gap: 10px;
		padding: 4px 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			border: 1px solid #000;
		}

	}

	&--fog {
		display: grid;
		grid-template-columns: repeat(3, 125px);
		grid-template-rows: repeat(2, 1fr);

		a {
			padding: 8px 15px;
		}

		img {
			max-height: 25px;
		}
	}
}
