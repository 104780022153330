@use '../designSystem/common/sassVars' as *;

input[type="search"] {
	appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
	resize: vertical;
	padding: 10px;
}

input.toggler {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
	appearance: none;
}

/* INPUTS */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
select,
textarea {
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
	border-top: 1px solid #b4b4b4;
	border-left: 1px solid #d1d1d1;
	margin-bottom: 20px;
	outline: none;
	color: #000;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"] {
	height: 30px;
}

select {
	padding: 3px 0 3px 6px;
}

button[disabled],
input[disabled] {
	opacity: .6
}

.DLForm {
	background-color: #f2f2f2;
	margin: 0 auto;
	padding: 40px 40px 30px;
	position: relative;

	@media (min-width: $scr-sm-min) {
		width: 530px;
	}

	@media (max-width: $scr-sm) {
		margin: 0 -20px;
		padding: 20px;
	}

	dl {
		margin: 0;
	}

	dt {
		float: left;
		padding: 0 10px 10px 0;
		width: 130px;
		clear: left;

		label {
			opacity: .5;
			position: relative;
			top: 4px;
		}
	}

	dd {
		padding: 0 0 10px 150px;
		margin-left: 0;
	}

	select,
	textarea,
	input[type="text"],
	input[type="email"],
	input[type="password"] {
		width: 100%;
		margin-bottom: 10px;
	}

	textarea {
		margin-bottom: 30px;
	}

	input[type="text"]:focus,
	input[type="email"]:focus,
	textarea:focus,
	select:focus {
		background-color: #E3F6FE;
		border-color: #85b6cb
	}

	.checkbox {
		input[type="checkbox"] {
			float: left;
			position: relative;
			top: 3px
		}

		label {
			display: block;
			margin-left: 30px;
		}
	}

}

@media (max-width: $scr-xs) {
	.DLForm {
		dt {
			float: none;
			padding: 0;
			width: auto;
		}

		dd {
			padding: 0;
			margin: 10px 0;
		}
	}
}

.formTip {
	opacity: .6;
	display: block;
	font-size: 11px;
}
