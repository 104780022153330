@use '../../../../designSystem/common/sassVars' as *;

.navbar__search__form--desktop {
    @media (max-width: $navbar-breakpoint-max) {
        display: none;
    }

    .navbar__search__input.ds-formInput {
        @media (min-width: $navbar-breakpoint-min) {
            width: 482px;

            .navbar--geoEnabled & {
                width: 430px;
            }
        }
    }
}
