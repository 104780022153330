@use '../../designSystem/common/sassVars' as *;
@use '../../designSystem/mixins/border-arrow' as *;

.navbar__menu {
	.dropdownMenu {
		a {
			color: #000;
		}

		&__contentWrapper {
			a:hover {
				text-decoration: underline;
			}
		}

		&__casino-strategy {
			& li:first-child {
				display: none;
			}

		}
	}

	@media (min-width: $navbar-breakpoint-min) {
		&>ul {
			justify-content: left;
			display: flex;
			box-shadow: 0 1px 0 var(--grey-400),
			0 -1px 0 var(--grey-400);
			background-color: #fff;
			padding: 0 var(--grid-side-padding-desktop);

			.site-en & {
				justify-content: space-between;
			}

			&>li {
				cursor: pointer;

				.site-en & {
					justify-content: space-between;
				}

				&>b {
					pointer-events: none;
				}

				&>a,
				&>b {
					line-height: 50px;
					font-weight: 400;
					font-size: 16px;
					color: #000;
					margin-right: 30px;

					.site-en & {
						margin-right: 0;
					}
				}

				&:hover,
				&.on,
				&.active {
					&>a,
					&>b {
						text-decoration: none;
						position: relative;
						display: block;

						&::after {
							content: '';
							position: absolute;
							bottom: -1px;
							left: 0;
							width: 100%;
							height: 2px;
							background-color: #000;
							z-index: 1;
						}
					}
				}

				.dropdownMenu {
					display: none;

				}

				&.on {
					.dropdownMenu {
						display: grid;
						cursor: auto;
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						background-color: #fff;
						padding: var(--grid-side-padding-desktop);
						grid-template-areas: "submenu contentWrapper";
						grid-template-columns: 1fr 4fr;
						column-gap: 2vw;
						margin-top: 1px;
						box-shadow: 0 1px 6px 0 rgb(0 0 0 / 12%);

						&__contentWrapper {
							grid-area: contentWrapper;
							display: grid;
							grid-template-areas: "content1 content2";
							grid-template-columns: 1fr 1fr;
							column-gap: 2vw;
							position: relative;

							&.noFirstColumn {
								column-gap: 0;
								grid-template-columns: 0 1fr;
							}

							&.noSecondColumn {
								column-gap: 0;
								grid-template-columns: 1fr 0;
							}

							&>div {
								max-width: 500px;
							}

						}

						&__content1 {
							grid-area: content1;
						}

						&__content2 {
							grid-area: content2;
						}

						&__submenu {
							grid-area: submenu;

							a {
								display: inline-block;
								font-size: 16px;
								line-height: 22px;
							}

							li:not(:last-child) a {
								margin-bottom: 15px;
							}

							li.on,
							li:hover {
								a {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: $navbar-breakpoint-max) {
		.navbar--userMenuOn &,
		.navbar--searchOn & {
			opacity: 0;
		}

		&>ul {
			border-top: 1px solid var(--grey-400);

			&>li {
				border-bottom: 1px solid var(--grey-400);
				position: relative;
				font-size: 16px;

				&::after {
					@include border-arrow(bottom, 14px, 8px);

					position: absolute;
					right: 0;
					top: 24px;
				}

				&>a,
				&>b {
					display: block;
					pointer-events: none;
					font-weight: 400;
					line-height: 56px;
				}

				.dropdownMenu {
					display: none;
				}

				&.on {
					&::after {
						transform: rotate(180deg);
					}

					&>a,
					&>b {
						font-weight: 600;
					}

					.dropdownMenu {
						display: block;
						border-left: 1px solid var(--grey-400);
						margin-bottom: 20px;

						&__contentWrapper {
							display: none;
						}

						a {
							display: block;
							line-height: 26px;
							margin-bottom: 10px;
							padding-left: 20px;
						}

						.on a {
							font-weight: 600;
						}
					}
				}

			}
		}
	}
}
