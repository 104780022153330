@use '../designSystem/common/sassVars' as *;
@use "../designSystem/mixins/border-arrow" as *;

.breadcrumb {
    position: relative;
    z-index: 3;
    overflow: auto hidden;
    background: #fff;
    line-height: 37px;
    max-height: 40px;
    margin: -20px 0 20px;
    white-space: nowrap;
    display: flex;
    column-gap: 11px;
    align-items: center;

    @media (min-width: 1381px) {
        max-width: 990px;
    }

    @media (max-width: 1380px) and (min-width: $scr-md-min) {
        max-width: calc(100vw - 400px);
    }

    @media (max-width: $scr-lg) and (min-width: $scr-md-min) {
        max-width: calc(100vw - 375px);
    }


    @media (min-width: $scr-sm-min) {
        line-height: 44px;
        max-height: 44px;
    }

    @media (min-width: $scr-lg-min) {
        margin: -30px 0 20px;
    }


    &>* {
        display: inline-block;
        color: inherit;

        &::before {
            @include border-arrow(right, 8px, 4px, primary);

            margin: 0 12px 0 -2px;
            content: '';
            vertical-align: middle;
            position: relative;
            top: 0;
            pointer-events: none;
        }

        @media (min-width: $scr-sm-min) {
            &:last-child {
                padding-right: 0;
            }
        }
    }

    a {
        color: var(--primary-500);

        &:hover {
            color: var(--primary-600);
            opacity: .8;
        }

        &.breadcrumb--home::before {
            content: normal;
        }
    }

    span {
        color: var(--grey-700);

        &::before {
            border-color: var(--primary-500);
        }
    }

    &--long {
        @media (max-width: $scr-sm) {
            i {
                display: none;
            }
        }

        @media (min-width: $scr-sm-min) {
            i {
                order: 3 !important;

                &::before {
                    border-color: var(--primary-500);
                }
            }

            &>* {
                display: none;

                &:nth-child(2) {
                    order: 1
                }

                &:nth-child(3) {
                    order: 2
                }

                &:nth-child(7),
                &:nth-child(6) {
                    overflow: hidden;
                    max-width: 300px;
                    text-overflow: ellipsis;
                }

                &:nth-last-child(-n+3) {
                    order: 6
                }

                &:nth-last-child(-n+3),
                &:nth-child(-n+3) {
                    display: inline-block;
                }
            }
        }
    }
}
