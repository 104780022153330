@use "sass:list";

@mixin border-arrow($direction, $width: 12px, $height: 6px, $color: black) {
    @if not list.index(top right bottom left, $direction) {
        @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }

    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: $width;
    height: $height;

    @if $color == primary {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi42NCIgaGVpZ2h0PSI3LjQzNCI+PHBhdGggZD0iTS4zMTIuMzE2YTEuMDc2IDEuMDc2IDAgMCAwIDAgMS41MTRsNS4zMDcgNS4yOTJhMS4wNjkgMS4wNjkgMCAwIDAgMS40NzUuMDMzbDUuMjMtNS4yMTNBMS4wNjkgMS4wNjkgMCAxIDAgMTAuODE1LjQyOEw2LjMyMyA0Ljg1NCAxLjgyNS4zMTFBMS4wNyAxLjA3IDAgMCAwIC4zMTEuMzE3eiIgc3R5bGU9ImZpbGw6IzAwNjlEMCIvPjwvc3ZnPgo=');
    } @else if$color == black {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi42NCIgaGVpZ2h0PSI3LjQzNCI+PHBhdGggZD0iTS4zMTIuMzE2YTEuMDc2IDEuMDc2IDAgMCAwIDAgMS41MTRsNS4zMDcgNS4yOTJhMS4wNjkgMS4wNjkgMCAwIDAgMS40NzUuMDMzbDUuMjMtNS4yMTNBMS4wNjkgMS4wNjkgMCAxIDAgMTAuODE1LjQyOEw2LjMyMyA0Ljg1NCAxLjgyNS4zMTFBMS4wNyAxLjA3IDAgMCAwIC4zMTEuMzE3eiIgc3R5bGU9ImZpbGw6IzI1MjUyNSIvPjwvc3ZnPgo=');
    } @else if$color == white {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi42NCIgaGVpZ2h0PSI3LjQzNCI+PHBhdGggZD0iTS4zMTIuMzE2YTEuMDc2IDEuMDc2IDAgMCAwIDAgMS41MTRsNS4zMDcgNS4yOTJhMS4wNjkgMS4wNjkgMCAwIDAgMS40NzUuMDMzbDUuMjMtNS4yMTNBMS4wNjkgMS4wNjkgMCAxIDAgMTAuODE1LjQyOEw2LjMyMyA0Ljg1NCAxLjgyNS4zMTFBMS4wNyAxLjA3IDAgMCAwIC4zMTEuMzE3eiIgc3R5bGU9ImZpbGw6I2ZmZmZmZiIvPjwvc3ZnPgo=');
    }

    @if $direction == top {
        transform: rotate(180deg);
    } @else if$direction == bottom {
        transform: rotate(0);
    } @else if$direction == left {
        transform: rotate(90deg);
    } @else if$direction == right {
        transform: rotate(-90deg);
    }
}
