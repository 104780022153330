.outlinedWidget {
	border: 1px solid var(--grey-400);
	border-radius: var(--rad);

	header {
		padding: 20px;
		border-bottom: 1px solid var(--grey-400);
		text-align: center;

		h3 {
			margin: 0;
		}

	}

	footer {
		text-align: center;
		line-height: 48px;
		border-top: 1px solid var(--grey-400);

		a {
			text-transform: uppercase;
			font-weight: 600;
			color: var(--primary-500);

			&:hover {
				text-decoration: underline;
			}
		}

	}
}
