.ds-formCheckbox {
    &--small {
        grid-template-columns: 15px auto;
        column-gap: 8px;

        small {
            font-size: 10px;
            line-height: 14px;
        }

        input {
            width: 16px;
            height: 16px;

            &:checked::before {
                transform: scale(0.7);
            }
        }
    }
}
