@use '../../designSystem/common/sassVars' as *;

.navbar__toggle {
	@media (min-width: $navbar-breakpoint-min) {
		display: none;
	}

	@media (max-width: $navbar-breakpoint-max) {
		grid-area: navbar__toggler;
		width: 60px;
		height: var(--navbar-height);
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;
		display: block;
		position: relative;

		i {
			position: absolute;
			height: 2px;
			width: 20px;
			background-color: #000;
			border-radius: 3px;
			left: 20px;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
			pointer-events: none;

			&:nth-child(1) {
				top: 22px;
			}

			&:nth-child(2),
			&:nth-child(3) {
				top: 28px;
			}

			&:nth-child(4) {
				top: 34px;
			}
		}

		.activeMenu & {
			i {
				&:nth-child(1) {
					top: 28px;
					width: 0%;
					left: 50%;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
					top: 30px;
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
					top: 30px;
				}

				&:nth-child(4) {
					top: 28px;
					width: 0%;
					left: 50%;
				}
			}

		}
	}
}
