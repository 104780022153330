@use '../../../designSystem/common/sassVars' as *;

.navbar__search__preloader {
    border: solid 1px var(--grey-400) !important;
	border-radius: var(--rad);

    @media (min-width: $navbar-breakpoint-min) {
        --preloader-box-width: 482px;

        .navbar--geoEnabled & {
            width: 430px;
        }
    }

    @media (max-width: $navbar-breakpoint-max) {
        --preloader-box-width: 20px;
    }
}
