@use '../designSystem/common/sassVars' as *;
@use "../designSystem/mixins/bp" as *;

@supports (display: grid) {
	.conversionBanner {
		display: grid;
		gap: 1px;

		&--1 {
			&>a {
				grid-template-areas: "logo title text cta";
			}
		}

		&--2 {
			@media (min-width: 601px) {
				grid-template-columns: 1fr 1fr;
			}

			&>a {
				grid-template:
					"logo title cta" auto
					"logo text cta" 1fr /
					50px 1fr auto;
			}
		}

		&--3 {
			grid-template-columns: 1fr 1fr 1fr;

			@include bp-mobile2 {
				grid-template-columns: 1fr;
			}

			&>a {
				grid-template:
					"logo title cta" auto
					"logo text cta" 1fr /
					50px 1fr auto;
			}
		}

		&>a {
			column-gap: 10px;
			align-items: flex-start;
			display: grid;

			&>div {
				grid-area: logo;
				align-self: start;
			}

			&>strong {
				grid-area: title;
			}

			&>p {
				grid-area: text;
			}

			&>span {
				grid-area: cta;
				align-self: center;
				text-align: center;
			}

			.terms {
				margin-top: 5px;

				--term-color: #fff;
			}
		}

		&.hideBonusText>a {
			align-items: center;
			padding: 0 10px;
			grid-template-areas: "logo title cta";
			grid-template-rows: auto;

			strong {
				margin: 0
			}
		}

	}

	.articleConversionBanner {
		.conversionBanner {
			a {
				text-decoration: none !important;
			}

			&--1 {
				@media (max-width: $scr-sm) {
					&>a {
						grid-template:
							"logo title cta" auto
							"logo text cta" 1fr /
							50px 1fr auto;
						row-gap: 5px;

						&.hasTerms {
							grid-template-areas:
								"logo title cta"
								"logo text cta"
								"terms terms terms";
							grid-template-rows: auto 1fr auto;
						}
					}
				}

				@media (min-width: $scr-sm-min) {
					&>a {
						grid-template-areas: "logo title text cta";
						grid-template-columns: 50px auto 1fr auto;
						column-gap: 25px;

						&.hasTerms {
							grid-template-areas:
								"logo title text cta"
								"terms terms terms terms";
						}

						&>* {
							align-self: center;
						}

						&>p {
							text-align: center;
							font-size: 18px;
							line-height: 24px;
						}

						&>strong span {
							font-size: 21px;
						}
					}
				}
			}

			&--2 {
				@media (min-width: 601px) {
					grid-template-columns: 1fr 1fr;
				}

				&>a {
					grid-template:
						"logo title cta" auto
						"logo text cta" 1fr
						50px 1fr auto;
					row-gap: 5px;

					&.hasTerms {
						grid-template-areas:
							"logo title cta"
							"logo text cta"
							"terms terms terms";
						grid-template-rows: auto 1fr auto;
					}
				}
			}

			&--3 {
				grid-template-columns: 1fr 1fr 1fr;

				@include bp-mobile {
					grid-template-columns: 1fr;

					&>a {
						grid-template:
							"logo title cta" auto
							"logo text cta" 1fr /
							50px 1fr auto;

						&.hasTerms {
							grid-template-areas:
								"logo title cta"
								"logo text cta"
								"terms terms terms";
						}
					}
				}

				&>a {
					grid-template:
						"logo title" auto
						"logo text" 1fr
						"cta cta" auto /
						50px 1fr;
					row-gap: 5px;

					&.hasTerms {
						grid-template-areas:
							"logo title"
							"logo text"
							"terms terms"
							"cta cta";
						grid-template-rows: auto auto 1fr auto;

						.terms {
							align-self: end;
						}
					}

					span {
						margin-top: 10px;
					}
				}
			}
		}
	}
}

@supports not (display: grid) {
	.conversionBanner>a {
		margin-bottom: 1px;
		display: table;
		width: 100%;

		&>* {
			display: table-cell;
			vertical-align: middle;
		}

		&>p {
			width: 100%;
			padding: 0 20px;
		}

		&>strong {
			white-space: nowrap;
			padding-left: 20px;
		}
	}
}

#main {
	.conversionBanner {
		&>a {
			color: #fff;

			&.light {
				color: #000;

				.terms {
					--term-color: #000;
				}
			}

			&.dark {
				color: #fff;
			}
		}
	}
}

.conversionBanner {
	margin-bottom: 20px;

	&>a {
		padding: 13px 10px;

	}

	img {
		min-width: 50px;
		height: 50px;
		display: block;
	}

	p {
		line-height: 16px;
		font-size: 14px;
		margin: 0;
		font-weight: 600;
	}

	strong {
		margin: -5px 0 5px;
		font-weight: 400;

		span {
			color: inherit;
			font-size: 16px;
			margin-right: 5px;
		}

		button {
			vertical-align: 2px;
		}
	}
}

.articleConversionBanner {
	background-color: var(--grey-100);
	overflow: hidden;
	clear: both;
	margin-bottom: 30px;
	margin-top: 30px;

	@media (min-width: $scr-sm-min) {
		margin-bottom: 40px;
		margin-top: 40px;
	}

	&>h2,
	&>h3,
	&>p {
		@media (min-width: $scr-sm-min) {
			margin: 15px;
		}

		@media (max-width: $scr-sm) {
			margin: 10px;
		}
	}

	&>p {
		font-size: 14px;

		@media (max-width: $scr-sm) {
			font-size: 13px;
			line-height: 18px;
		}
	}

	.conversionBanner {
		margin-bottom: 0;

		strong {
			margin: 0;
		}
	}
}
