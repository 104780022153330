/* stylelint-disable no-duplicate-selectors */
@use '../designSystem/common/sassVars' as *;

.pn-field {
	margin-bottom: 5px;

	--btn-width: 148px;

	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.pn-field > input[type] {
	// reset
	height: initial;
	padding: initial;
	border-bottom: initial;
	border-right: initial;
	border-top: initial;
	border-left: initial;
	outline: initial;
	color: initial;
	margin: initial;
}

.pn-field > input[type] {
	width: 100%;
	height: 48px;
	padding: 13px 15px;
	border-radius: 4px;
	border: solid 1px var(--grey-300);
	background-color: var(--grey-200);
	outline: none;
	margin-bottom: 5px;
}

.pn-field:focus-within > input[type] {
	border-color: #0046ba;
}

.pn-field .tip {
	background-color: initial;
	color: initial;
	font-weight: initial;
	font-size: initial;
	margin-bottom: initial;
	text-align: initial;
	padding: initial;
	width: 100%;
}

html.wf-active .pn-field .tip {
	font-family: inherit;
}

.pn-field .tip {
	display: none;
	line-height: 1;
	font-size: 13px;
	margin: 1px 0;
	color: var(--warning-color);
}

.pn-field.needAttention > .tip {
	display: block;
}

.pn-field.needAttention > input[type] {
	border-color: var(--warning-color);
}

@media (min-width: $scr-xs-min) {
	.pn-field.width-btn > input[type] {
		width: calc(100% - var(--btn-width) - 20px);
	}

	.pn-field.width-btn > input + button {
		width: var(--btn-width);
		margin-left: 20px;
	}
}

@media (max-width: $scr-xs) {
	.pn-field.width-btn > input[type] {
		width: 100%;
	}

	.pn-field.width-btn > input + button {
		margin-top: 8px;
		order: 1;
	}
}
