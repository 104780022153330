@use '../../designSystem/common/sassVars' as *;

.siteFooter__partners {
    grid-area: partners;

    h3 {
        margin-bottom: 20px;
    }

    ul {
        display: grid;
        margin-bottom: 10px !important;

        @media (min-width: $scr-sm-min) {
            grid-template-columns: repeat(5, 1fr);
            gap: 10px 20px;
        }

        @media (max-width: $scr-sm) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 5px;

        }
    }

    a {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 10px;
        align-items: center;
    }

    img {
        vertical-align: top;

        @media (min-width: $scr-sm-min) {
            width: 40px;
            height: 40px;
        }
    }

    &__disclaimer {
        color: var(--grey-600);
        line-height: 20px;
        margin: 0;
    }
}
