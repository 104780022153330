.ds-subscribe--ambassador {
	background: #252525;

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 3px dotted #E01B22;
		padding-bottom: 20px;
		margin-bottom: 20px;
		column-gap: 14px;

		figure {
			display: flex;
			align-items: center;
			column-gap: 14px;

			b {
				display: block;
			}
		}
	}

	h2 {
		font-size: 18px;
		line-height: 24px;
	}

	&.ds-subscribe.ds-darkTheme .ds-subscribe__button {
		color: #000;
	}
}
