@use "../designSystem/common/sassVars" as *;

.editButtonSitemap {
	top: 10px;
	right: 0;
	position: absolute;
	z-index: 3;

	.article & {
		z-index: 5;
	}

	@media (min-width: $navbar-breakpoint-min) {
		top: -15px;
	}
}

.controls,
.editButton {
	display: none !important;
}

#reportingMyStackTabItems .controls {
	position: static;
	float: none;
	justify-content: center;
	margin-bottom: 20px;
}

.isAdmBox {
	.editButton {
		display: inline-block !important;
		margin-bottom: 20px;
	}

	.controls {
		display: flex !important;
	}

	&[data-section="reporting"] .editButtonSitemap {
		display: none;
	}
}
