@use '../../../designSystem/common/sassVars' as *;
@use "../../../designSystem/mixins/overlay";
@use "./results/offers";
@use "./results/filter";
@use "./results/item";
@use "./results/viewAll";

.navbar__search__suggestionDropdown {
    background-color: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;

    @media (min-width: $navbar-breakpoint-min) {
        row-gap: 30px;
        position: absolute;
        top: calc(var(--navbar-height) - 4px);
        left: 0;
        right: 0;
        padding: 24px 24px 0;
        border-radius: var(--rad);
        border: 1px solid var(--grey-400);
    }

    @media (max-width: $navbar-breakpoint-max) {
        row-gap: 20px;
        position: fixed;
        inset: 0;
    }
}

.navbar__search__results__section {
    font-size: 16px;

    @media (min-width: $navbar-breakpoint-min) {
        overflow-y: auto;
   }

    @media (max-width: $navbar-breakpoint-max) {
        padding: 0 20px;
    }
}

.navbar__search__results {
    @media (max-width: $navbar-breakpoint-max) {
        height: 100%;
        overflow-y: auto;
    }
}

.navbar__search__results__wrapper {
    &.has-suggestions {
        display: grid;
        grid-template-rows: 32px 1fr 62px;
        row-gap: 20px;
    }

    @media (min-width: $navbar-breakpoint-min) {
        overflow: hidden;
        max-height: calc(100vh - 230px);
        height: 100%;
    }

    @media (max-width: $navbar-breakpoint-max) {
        max-height: calc(100vh - 81px);

        .preloaderBox {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}
