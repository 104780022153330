@mixin to-primary($color: #000000) {
	filter: invert(24%) sepia(98%) saturate(4435%) hue-rotate(199deg) brightness(96%) contrast(105%);
}

@mixin to-white($color: #000000) {
	filter: invert(1);
}

@mixin to-black($color: #000000) {
	filter: brightness(0);
}
