@use "articles";
@use "reportingUpcoming";
@use "players";
@use "rooms";
@use "logosList";
@use "tinyCards";
@use "rules";


.submenu {
    a:hover {
        text-decoration: underline;
    }

    .mb {
        margin-bottom: 30px;
    }

    .navbar-outlineimg {
        border-radius: 4px;
        border: 1px solid var(--grey-400);
        padding: 4px;
        display: inline-block;

        &:hover {
            border-color: #000;
        }

        img {
            vertical-align: top;
        }
    }
}
