@use "../../common/sassVars" as *;

.ds-subscribe.ds-subscribe--footer {
	max-width: 400px;
	padding: 0;

	.ds-subscribe__main__title {
		line-height: 18px;
	}

	.ds-subscribe__main__text {
		font-size: 13px;
		margin-bottom: 8px;
	}

	.ds-subscribe__main__form {
		row-gap: 10px;

		&__group {
			column-gap: 0;

			@media (min-width: $scr-sm-min) {
				.ds-formInput {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				.ds-subscribe__button {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}

			}

			.ds-formInput {
				font-size: 13px;
			}

			.ds-subscribe__button {
				color: var(--grey-800);
				font-size: 13px;
			}
		}

	}

}
