@use '../../designSystem/common/sassVars' as *;
@use './company';
@use './copy';
@use './gambling';
@use './partners';
@use './social';

:root {
	--section-space-desktop: 25px;
	--section-space-mobile: 30px;
}

.siteFooter {
	background-color: var(--grey-800);
	position: relative;
	display: grid;
	z-index: 5;
	column-gap: 10px;
	color: #fff;

	a {
		color: currentcolor;

		&:hover {
			color: #a8a8a8;
		}
	}

	h3 {
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 15px;
		margin-top: 0;
		font-weight: 700;
	}

	&>* {
		margin-bottom: var(--section-space-mobile);
	}

	@media (min-width: $scr-sm-min) {
		display: grid;
		grid-template-areas:
			'logo company company company'
			'whoWeAre howTo social subscribe'
			'partners partners partners partners'
			'gambling gambling gambling subs'
			'copy copy copy copy'
		;
		grid-template-columns: repeat(4, 1fr);
		padding: 30px;

		&__logo {
			border-bottom: 1px solid rgb(255 255 255 / 10%);
			padding-bottom: var(--section-space-desktop);
			display: flex;
			align-items: center;
			padding-right: 30px;
		}

		&__company {
			border-bottom: 1px solid rgb(255 255 255 / 10%);
			padding-bottom: var(--section-space-desktop);
		}

		&__partners {
			border-top: 1px solid rgb(255 255 255 / 10%);
			padding-top: var(--section-space-desktop);
		}

		&__gambling {
			border-top: 1px solid rgb(255 255 255 / 10%);
			padding-top: var(--section-space-desktop);
		}

		&__subs {
			border-top: 1px solid rgb(255 255 255 / 10%);
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			padding-bottom: 3px;
		}

		&__copy {
			border-top: 1px solid rgb(255 255 255 / 10%);
			padding-top: var(--section-space-desktop);
		}

		&>section>ul {
			li {
				margin-bottom: 5px;
			}
		}
	}

	@media (max-width: $scr-sm) {
		&>section {
			border-top: 1px solid rgb(255 255 255 / 10%);
			padding-top: 30px;

			ul {
				margin-bottom: -15px;
			}

			ul li {
				margin: 0 0 15px;
			}
		}

		padding: 50px 20px;
		grid-template-areas:
			'logo logo'
			'company company'
			'whoWeAre howTo'
			'partners partners'
			'social social'
			'subscribe subscribe'
			'gambling gambling'
			'subs subs'
			'copy copy'
		;
		grid-template-columns: repeat(2, 1fr);
	}

	&.siteFooter--5cols {
		@media (max-width: $scr-sm) {
			grid-template-areas:
				'logo logo'
				'company company'
				'whoWeAre howTo'
				'casino casino'
				'partners partners'
				'social social'
				'subscribe subscribe'
				'gambling gambling'
				'subs subs'
				'copy copy'
			;
		}

		@media (max-width: $scr-md) and (min-width: $scr-sm-min) {
			grid-template-areas:
				'logo company company company'
				'whoWeAre howTo casino social'
				'subscribe subscribe subscribe subscribe'
				'partners partners partners partners'
				'gambling gambling gambling subs'
				'copy copy copy copy'
			;
			grid-template-columns: repeat(4, 1fr);
		}

		@media (min-width: $scr-md-min) {
			display: grid;
			grid-template-areas:
				'logo company company company company'
				'whoWeAre howTo casino social subscribe'
				'partners partners partners partners partners'
				'gambling gambling gambling gambling subs'
				'copy copy copy copy copy'
			;
			grid-template-columns: 1fr 1fr 1fr 0.8fr 1.4fr;
		}
	}

	&__subscribe {
		grid-area: subscribe;
	}

	&__casino {
		grid-area: casino;
	}

	&__logo {
		grid-area: logo;
	}

	&__howTo {
		grid-area: howTo;

		@media (max-width: $scr-sm) {
			padding-left: 5px;
		}
	}

	&__whoWeAre {
		grid-area: whoWeAre;

		@media (max-width: $scr-sm) {
			border-top: 1px solid rgb(255 255 255 / 10%);
		}
	}

	&__subs {
		grid-area: subs;
	}
}
