@use "dark";

.ds-formInput {
	width: 100%;
	min-height: 38px;
	margin: 0 !important;
	padding: 8px 16px;
	border: solid 1px var(--grey-400) !important;
	border-radius: var(--rad);
	font-size: 16px;

	&--large {
		min-height: 48px;
	}

	&--medium {
		min-height: 40px;
	}

	&--darkTheme {
		background-color: transparent;
		color: #fff !important;

		&::placeholder {
			color: #fff !important;
		}
	}
}
