.ds-subscribe.ds-darkTheme {
    color: #fff;

    .loading button::before {
        filter: brightness(0.5);
    }

    .ds-subscribe__button {
        background: #fff;
        color: #003F7D;
        font-size: 14px;
    }
}
