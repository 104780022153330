@use "../designSystem/mixins/overlay" as *;
@use "../designSystem/mixins/cross" as *;

.popup-banner .inner {
    @include overlay;

    background-color: #000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100003;

    .close {
        position: absolute;
        width: 30px;
        height: 30px;
        background: var(--primary-500);
        top: 0;
        right: -30px;
        cursor: pointer;

        &::after {
            content: '';

            @include cross(#fff, 20px, 2px);

            display: block;
            margin: 6px auto;
        }
    }

    .offers {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        li {
            background: #fff;
            width: 220px;
            height: 230px;
            text-align: center;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
        }

        figure {
            background-color: #000;
            padding: 5px 20px;
            line-height: 80px;

            img {
                vertical-align: middle;
                max-height: 70px;
                max-width: 150px;
                display: inline !important;
            }
        }

        p {
            color: #707070;
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            margin: 0;
            padding: 15px 15px 0;
            align-self: center;
        }

        div {
            padding: 0 15px 15px;
            align-self: end;
        }

        a {
            background-color: var(--primary-500);
            color: #fff;
            display: block;
            border-radius: 20px;
            line-height: 40px;

        }

    }
}
