@use "sass:string";
@use "sass:list";
@use "sass:map";
@use "sass:math";

@function opposite-direction($directions) {
	$opposite-directions: ();
	$direction-map: ('top': 'bottom',
		'right': 'left',
		'bottom': 'top',
		'left': 'right',
		'center': 'center',
		'ltr': 'rtl',
		'rtl': 'ltr'
	);

	@each $direction in $directions {
		$direction: string.to-lower-case($direction);

		@if map.has-key($direction-map, $direction) {
			$opposite-directions: list.append($opposite-directions, map.get($direction-map, $direction));
		} @else {
			@warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
		}
	}

	@return $opposite-directions;
}

@mixin triangle($direction, $size: 1em, $cof: 1, $color: currentcolor) {
	@if not list.index(top right bottom left, $direction) {
		@error "Direction must be either `top`, `right`, `bottom` or `left`.";
	}

	width: 0;
	height: 0;
	content: '';
	border-#{opposite-direction($direction)}: math.round($size * $cof) solid $color;

	$perpendicular-borders: $size solid transparent;

	@if $direction == top or $direction == bottom {
		border-left: $perpendicular-borders;
		border-right: $perpendicular-borders;
	} @else if$direction == right or $direction == left {
		border-bottom: $perpendicular-borders;
		border-top: $perpendicular-borders;
	}
}
