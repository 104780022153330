.ds-button {
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 6px;
	}

	&.ds-button--iconLeft span {
		flex-direction: row-reverse;
	}

	&.ds-button--iconRight span {
		flex-direction: row;
	}

  }
