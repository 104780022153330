@use '../../../../designSystem/common/sassVars' as *;

.navbar__search__form--dropdown {
    @media (min-width: $navbar-breakpoint-min) {
        display: none;
    }

    @media (max-width: $navbar-breakpoint-max) {
        display: grid;
        grid-template-areas: "close input";
        grid-template-columns: auto 1fr;
        border-bottom: 1px solid var(--grey-400);
        padding: 10px 0;
    }
}

.navbar__search__form__inputGroup {
    grid-area: input;
    position: relative;

    @media (max-width: $navbar-breakpoint-max) {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    @media (min-width: $navbar-breakpoint-min) {
        &::before {
            content: '';
            top: 0;
            bottom: 0;
            position: absolute;
            left: 16px;
            width: 18px;
            background-image: url('../../../web/img/svg/suggestion-spyglass.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 18px;
            opacity: .5;
        }

        &:focus-within::before {
            opacity: 1;
        }
    }
}

.navbar__search__input.ds-formInput {
    min-height: 40px;


    @media (min-width: $navbar-breakpoint-min) {
        padding-left: 46px;

        &:focus {
            border-color: black !important;
        }
    }

    @media (max-width: $navbar-breakpoint-max) {
        border: none !important;
        padding-left: 0;
        padding-right: 0;
    }
}

.navbar__search__close {
    grid-area: close;

    @media (max-width: $navbar-breakpoint-max) {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNNiAxIDEgNm0wIDAgNSA1TTEgNmgxMiIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        background-position: center;
        width: 54px;

        span {
            opacity: 0;
        }
    }
}

.navbar__search__clear {
    height: 40px;
    background: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiPjxwYXRoIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTS4yNC4yNGEuOC44IDAgMCAxIDEuMTMgMEw3IDUuODYgMTIuNjMuMjRhLjguOCAwIDAgMSAxLjEzIDEuMTNMOC4xNCA3bDUuNjIgNS42M2EuOC44IDAgMSAxLTEuMTMgMS4xM0w3IDguMTRsLTUuNjMgNS42MmEuOC44IDAgMSAxLTEuMTMtMS4xM0w1Ljg2IDcgLjI0IDEuMzdhLjguOCAwIDAgMSAwLTEuMTNaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=') no-repeat center;
    font-size: 0;
    border: none;
    padding: 0;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    @media (min-width: $navbar-breakpoint-min) {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
    }

    @media (max-width: $navbar-breakpoint-max) {
        width: 54px;
    }

}
