@use '../../designSystem/common/sassVars' as *;

.siteFooter__gambling {
    grid-area: gambling;

    a {
        display: inline-block;
    }

    &>img,
    div > a {
        vertical-align: middle;
    }

    div {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
    }

    img {
        vertical-align: middle;
        max-height: 30px;
        max-width: 130px;
    }

    @media (max-width: $scr-sm) {
        border-top: 1px solid rgb(255 255 255 / 10%);
        border-bottom: 1px solid rgb(255 255 255 / 10%);
        padding-bottom: var(--section-space-mobile);
        padding-top: var(--section-space-mobile);

        div {
            gap: 20px;
        }

        h3 {
            margin-top: 0;
        }

    }

}
