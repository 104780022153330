@use '../../../../designSystem/common/sassVars' as *;

.navbar__search__results__filterScrollWrapper {
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 8px;
    scroll-snap-type: x mandatory;

    @media (min-width: $navbar-breakpoint-min) {
        padding-right: 18px;
        padding-left: 24px;
        margin-left: -24px;
        margin-right: -24px;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.navbar__search__results__filter {
    align-items: flex-end;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    white-space: nowrap;

    button {
        margin-right: 12px;
    }
}
